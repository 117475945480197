import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as SistemaActions from '../actions/sistema.actions';
import { SistemaService } from '../service/sistema.service';

@Injectable()
export class SistemaEffects {
  loadSistemas$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SistemaActions.loadSistemas),
      mergeMap(() =>
        this.sistemaService.findSistemaSolicita().pipe(
          map(sistemas => SistemaActions.loadSistemasSuccess({ sistemas })),
          catchError(error => of(SistemaActions.loadSistemasFailure({ error })))
        )
      )
    )
  );

  loadSistemaByUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SistemaActions.loadSistemaByUser),
      mergeMap((action) =>
        this.sistemaService.findAllSistemasByIdUsuarioRepresentante(action.idUsuarioRepresentante).pipe(
          map(sistemasUser => SistemaActions.loadSistemaByUserSuccess({ sistemasUser })),
          catchError(error => of(SistemaActions.loadSistemaByUserFailure({ error })))
        )
      )
    )
  );

  loadSistemaByUsuarioRepresentanteId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SistemaActions.loadSistemaByUsuarioRepresentanteId),
      mergeMap(({ idUsuarioRepresentante }) =>
        this.sistemaService.findAllSistemasByIdUsuarioRepresentante(idUsuarioRepresentante).pipe(
          map(sistemas => SistemaActions.loadSistemaByUsuarioRepresentanteIdSuccess({idUsuarioRepresentante: idUsuarioRepresentante, sistemas })),
          catchError(error => of(SistemaActions.loadSistemaByUsuarioRepresentanteIdFailure({ error })))
        )
      )
    )
  );


  constructor(private actions$: Actions, private sistemaService: SistemaService) {}
}
