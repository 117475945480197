import { createSelector, createFeatureSelector } from '@ngrx/store';
import { createIsLoadedSelector } from './genericselector.interface';
import { PerfilInternoState } from '../interfaces/perfilinterno-state.interface';

export const selectPerfilInternoState = createFeatureSelector<PerfilInternoState>('perfisInterno');

export const selectAllPerfisInterno = createSelector(
  selectPerfilInternoState,
  state => state.perfisInterno
);

export const selectAllPerfilInterno = createSelector(
  selectPerfilInternoState,
  state => state.perfilInterno
);

export const selectIsUpdatedPerfilInterno = createSelector(
  selectPerfilInternoState,
  (state: PerfilInternoState) => state.updated
);

export const getPerfisInternoIsLoaded =  createIsLoadedSelector(selectPerfilInternoState);

