<h1 mat-dialog-title>Pesquisar Organização com CNES</h1>
<div mat-dialog-content>
  <div class="main-content" id="main-content">
    <div class="row">
      <div>
        <fieldset>
          <div class="row">
            <div class="col-md-12">
              <div class="br-input">
                <label for="cnpj">CNES</label>
                <input
                  id="cnpj"
                  [(ngModel)]="cnes"
                  type="text"
                  placeholder="Preencha o CNES"
                  mask="0000000"
                />
              </div>
            </div>
          </div>
          <div mat-dialog-actions style="margin-bottom: 15px;">
            <button
              cdkFocusInitial
              (click)="findInstituicoesByCnes(0, 20)"
              class="button input-button-custom"
              [disabled]="isLoading"
            >
              Pesquisar
            </button>
            <button
              mat-dialog-close
              class="button input-button-white-custom"
              (click)="reset()"
              [disabled]="isLoading"
            >
              Cancelar
            </button>
          </div>
          <div class="row" *ngIf="dataSource.data.length > 0"> <!-- updated variable name -->
            <div class="br-input" style="margin-bottom: 8px;">
              <label for="cnpj">Instituições sob o CNES {{ cnes }}</label>
            </div>
            <div class="mat-elevation-z8">
              <mat-table #table [dataSource]="dataSource">
                <ng-container matColumnDef="id">
                  <mat-header-cell *matHeaderCellDef> Selecione </mat-header-cell>
                  <mat-cell *matCellDef="let instituicao">
                    <div style="padding-left: 24px !important; width: 100%;">
                      <input
                        type="radio"
                        id="id_{{ instituicao.id }}"
                        name="id"
                        value="{{ instituicao.id }}"
                        (click)="selecionaInstituicao(instituicao)"
                      />
                    </div>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="cnpj">
                  <mat-header-cell *matHeaderCellDef> CNPJ </mat-header-cell>
                  <mat-cell *matCellDef="let instituicao">
                    {{ formatCnpj(instituicao.numeroCNPJ) }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="cnes">
                  <mat-header-cell *matHeaderCellDef> CNES </mat-header-cell>
                  <mat-cell *matCellDef="let instituicao">
                    {{ instituicao.codigoCnes }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="razaoSocial">
                  <mat-header-cell *matHeaderCellDef> Razão Social </mat-header-cell>
                  <mat-cell *matCellDef="let instituicao">
                    {{ instituicao.razaoSocial }}
                  </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
              </mat-table>

              <mat-paginator
                #paginatorPageSize
                [pageSizeOptions]="pageSizes"
                [length]="totalElements"
                (page)="nextPage($event)"
                showFirstLastButtons
              >
              </mat-paginator>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
  </div>
</div>
