import { Component, inject, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BaseComponent } from "../util/base.component";
import { ToastrService } from "ngx-toastr";
import {
  filter,
  map,
  Subject,
  Subscription,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from "rxjs";
import { HomeService } from "../service/home.service";
import { environment } from "src/environments/environment";
import { Functions } from "../util/functions";
import { AppState } from "../interfaces/app-state.interface";
import { Store } from "@ngrx/store";
import { loadGovBrAccessToken, logout } from "../actions/auth.actions";
import { loadEmpresas, updateEmpresas } from "../actions/company.action";
import { GovBrToken } from "../model/govbrtoken";
import { selectGovBrToken, selectIsResponsavelLegal, selectIsAdministrador, selectIsGestorAnvisa, selectIsGestorAnvisaConsulta, selectIsGestorCadastros } from "../selectors/auth.selectors";
import {
  selectEmpresas,
  selectEmpresasLoaded,
} from "../selectors/company.selector";
import { MatDialog } from "@angular/material/dialog";
import { DialogLoadEmpresasComponent } from "./dialog-load-empresas/dialog-load-empresas.component";
import { Constants } from "../util/constants";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent extends BaseComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  isPerfilGestorAnvisa = false;
  isPerfilGestorAnvisaConsulta = false;
  isAdministrador = false;
  private homeSubscription!: Subscription;

  readonly dialog = inject(MatDialog);

  constructor(
    public toastr: ToastrService,
    public activatedRoute: ActivatedRoute,
    private homeService: HomeService,
    private store: Store<AppState>
  ) {
    super();  
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogLoadEmpresasComponent, {
      width: "500px",
      height: "500px",
    });

    dialogRef.afterClosed().subscribe(() => {
      this.logout();
    });
  }

  logout() {
    this.store.dispatch(logout());
  }

  ngOnInit(): void {
    if (!environment.IS_LOCAL) {
      const token = Functions.getUsuarioLogadoFromSession().token;
      this.handleGovBrToken(token);
    }
    this.configuraPerfis();
    this.homeSubscription = this.homeService.home$.subscribe(() => {
      this.configuraPerfis();
    });
  }

  private configuraPerfis(): void {
    const usuarioLogado = Functions.getUsuarioLogadoFromSession();
    this.isPerfilGestorAnvisa = usuarioLogado.selectedProfile === Constants.PERFIL_GESTOR_ANVISA;
    this.isPerfilGestorAnvisaConsulta = usuarioLogado.selectedProfile === Constants.PERFIL_GESTOR_ANVISA_CONSULTA;
    this.isAdministrador = usuarioLogado.selectedProfile === Constants.PERFIL_ADMINISTRADOR;
  }

  private handleGovBrToken(keycloakAccessToken: string) {
    this.store.dispatch(loadGovBrAccessToken({ token: keycloakAccessToken }));

    // Wait for the GovBrToken to be loaded and then load empresas
    this.store
      .select(selectGovBrToken)
      .pipe(
        filter((token): token is GovBrToken => !!token),
        tap((token) =>
          this.store.dispatch(
            loadEmpresas({ govBrAccessToken: token.access_token })
          )
        ),
        takeUntil(this.destroy$)
      )
      .subscribe();
    

    // Handling the loaded state and then wait for empresas
    this.store
      .select(selectEmpresasLoaded)
      .pipe(
        filter((loaded) => loaded === true), // Ensure it only proceeds when loaded is true
        switchMap(() =>
          this.store.select(selectEmpresas).pipe(
            withLatestFrom(
              this.store.select(selectIsResponsavelLegal),
              this.store.select(selectIsAdministrador),
              this.store.select(selectIsGestorAnvisa),
              this.store.select(selectIsGestorAnvisaConsulta),
              this.store.select(selectIsGestorCadastros)
            ),
            map(([empresas, isResponsavelLegal, isAdministrador, isGestorAnvisa, isGestorAnvisaConsulta, isGestorCadastros]) => {
              const cnpjs = empresas.length > 0 ? empresas.map((empresa) => empresa.cnpj) : [];
              const hasOtherProfiles = isAdministrador || isGestorAnvisa || isGestorAnvisaConsulta || isGestorCadastros;
              const hasNoProfiles = !isResponsavelLegal && !isAdministrador && !isGestorAnvisa && !isGestorAnvisaConsulta && !isGestorCadastros;
              return { cnpjs, isResponsavelLegal, hasOtherProfiles, hasNoProfiles };
            }),
            tap(({ cnpjs, isResponsavelLegal, hasOtherProfiles, hasNoProfiles }) => {
              this.store.dispatch(updateEmpresas({ cnpjs }));
              if ((cnpjs.length === 0 && isResponsavelLegal && !hasOtherProfiles) || hasNoProfiles) {
                this.openDialog();
              }
            })
          )
        ),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
