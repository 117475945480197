/* eslint-disable @typescript-eslint/no-unused-vars */
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { KeycloakService } from "keycloak-angular";
import { environment } from "src/environments/environment";
import { Observable, throwError } from "rxjs";
import { Logs } from "../model/logs";

@Injectable({
  providedIn: "root",
})
export class LogService {
  private readonly API = `${environment.API}/api/v1/logs`;

  private token: unknown;

  constructor(
    private httpClient: HttpClient,
    private readonly keycloackService: KeycloakService
  ) {}

  getToken() {
    return this.keycloackService.getToken();
  }

  getLogsByAutorNomeAndInterval(
    autorNome: string,
    joinPessoaJuridica: boolean,
    idPessoaJuridica: string | null,
    interval: number,
    page: number,
    size: number
  ): Observable<Logs> {
    const api_key = this.getToken();
  
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${api_key}`,
    });
  
    let params = new HttpParams()
      .set('autorNome', autorNome)
      .set('joinPessoaJuridica', String(joinPessoaJuridica))
      .set('interval', String(interval))
      .set('page', String(page))
      .set('size', String(size));
  
    if (idPessoaJuridica) {
      params = params.set('idPessoaJuridica', idPessoaJuridica);
    }
  
    return this.httpClient.get<Logs>(`${this.API}/find-logs-by-autor-e-intervalo`, { headers, params });
  }
  
  
  
}
