<header class="br-header mb-4" id="header" data-sticky="data-sticky" style="z-index: 100;">
    <div class="container-lg">
      <div class="header-top">
        <!-- header Logo-->
        <div class="header-logo">
          <img src="https://www.gov.br/anvisa/++theme++padrao_govbr/img/govbr-logo-medium.png" alt="logo gov-br">
          <div class="header-sign">Ministério da Saúde</div>
        </div>
        <div class="header-actions">
          <div class="header-links dropdown">
            <button class="br-button circle small" type="button" data-toggle="dropdown"
              aria-label="Abrir Acesso Rápido"><i class="fas fa-ellipsis-v" aria-hidden="true"></i>
            </button>
            <div class="br-list">
              <div class="header">
                <div class="title">Acesso Rápido</div>
              </div><a class="br-item" href="https://www.gov.br/pt-br/orgaos-do-governo">Órgãos do Governo</a>
              <a class="br-item" href="https://www.gov.br/acessoainformacao/pt-br">Acesso à Informação</a>
              <a class="br-item" href="http://www4.planalto.gov.br/legislacao">Legislação</a>
              <a class="br-item"
                href="https://www.gov.br/governodigital/pt-br/acessibilidade-digital">Acessibilidade</a>
            </div>
          </div>
          <div class="header-functions dropdown">
            <button class="br-button circle small" type="button" data-toggle="dropdown"
              aria-label="Abrir Funcionalidades do Sistema"><i class="fas fa-th" aria-hidden="true"></i>
            </button>            
          </div>          
        </div>
      </div>            
    </div>
  </header>
