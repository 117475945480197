import { Component } from '@angular/core';
import { KeycloakService } from "keycloak-angular";
import { Observable, from } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Cadastro Anvisa';
  isLoggedIn$: Observable<boolean>;
  
  constructor(private keycloakService: KeycloakService) {
    this.isLoggedIn$ = from(this.keycloakService.isLoggedIn());
  }

}
