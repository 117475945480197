<h1 mat-dialog-title>Selecionar Empresas que serão vinculadas ao Gestor de Cadastro</h1>
<div mat-dialog-content style="height: 200px;">  
    <div class="main-content pl-sm-3 mt-4" id="main-content">
      <div class="row">
        <form class="example-form">
            <mat-form-field class="example-full-width">
              <mat-label>Pessoas Jurídicas</mat-label>
              <mat-select [formControl]="pessoaJuridicaFormControl" [(ngModel)]="cnpjs" multiple>
                <mat-option *ngFor="let pj of pessoasjuridicas" [value]="pj.id">
                  {{pj.razaoSocial}}
                </mat-option>                     
              </mat-select>
            </mat-form-field>
          </form>
      </div>           
  </div>
</div>
<div mat-dialog-actions align="center" style="margin-bottom: 15px;">
  <button mat-button mat-dialog-close cdkFocusInitial (click)="savePessoasJuridicas()">Salvar</button>
  <button mat-button>Limpar</button>
  <button mat-button mat-dialog-close>Cancelar</button>
</div>



    
 

