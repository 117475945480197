import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { KeycloakService } from "keycloak-angular";
import { environment } from "src/environments/environment";
import { Observable, catchError } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PorteService {
  public API = `${environment.API}/api/v1/porte`;
  private token: unknown;

  constructor(
    private httpClient: HttpClient,
    private readonly keycloackService: KeycloakService
  ) {}

  getToken() {
    return this.keycloackService.getToken();
  }

  findPorteDescricaoById(id: number): Observable<string> {
    this.token = this.getToken();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.token}`,
    });
    const requestOptions = { headers: headers, responseType: 'text' as 'json' };

    return this.httpClient.get<string>(`${this.API}/${id}`, requestOptions)
      .pipe(
        catchError(error => {
          console.error('Error fetching data', error);
          throw error;
        })
      );
  }
}
