/* eslint-disable @typescript-eslint/no-unused-vars */
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { KeycloakService } from "keycloak-angular";
import { environment } from "src/environments/environment";
import { Observable, throwError } from "rxjs";
import { Sistema } from "../model/sistema.model";
import { Constants } from "../util/constants";

@Injectable({
  providedIn: "root",
})
export class SistemaService {
  public readonly API = `${environment.API}/api/v1/sistemas`;

  private token: unknown;

  constructor(
    private httpClient: HttpClient,
    private readonly keycloackService: KeycloakService
  ) {}

  getToken() {
    return this.keycloackService.getToken();
  }

  findSistemaSolicita(): Observable<Sistema[]> {
    this.token = this.getToken();
    const api_key = this.token;

    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${api_key}`,
    });

    return this.httpClient.get<Sistema[]>(this.API + "/find-sistema-solicita", {
      headers: headers,
    });
  }

  findAllSistemas(): Observable<Sistema[]> {
    this.token = this.getToken();
    const api_key = this.token;

    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${api_key}`,
    });

    return this.httpClient.get<Sistema[]>(this.API + "/find-all-sistemas", {
      headers: headers,
    });
  }

  findAllSistemasByParams(
    perfilNo?: string
  ): Observable<Sistema[]> {
    
    this.token = this.getToken();
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.token}`,
    });

    let params = new HttpParams();

    if (perfilNo) {
      params = params.set("perfilNo", perfilNo);
    }

    return this.httpClient.get<Sistema[]>(
      `${this.API}/find-all-sistemas-by-params`,
      { headers, params }
    );
  }

  findAllSistemasByIdUsuarioRepresentante(
    idUsuarioRepresentante: string
  ): Observable<Sistema[]> {

    this.token = this.getToken();
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.token}`,
    });

    return this.httpClient.get<Sistema[]>(
      `${this.API}/find-all-sistemas-by-id-usuario-representante/${idUsuarioRepresentante}`,
      { headers }
    );
  }
}
