import { createAction, props } from '@ngrx/store';
import { PerfilInterno } from '../model/perfilinterno';
import { PerfisInternoColaboradorRequest } from '../model/perfisinternocolaboradorrequest.model';
import { PerfisInterno } from '../model/perfisinterno';

export const choosePerfisInterno = createAction(
  '[Perfil Interno] Choose Perfis Interno',
  props<{ nuCpf: string, noPessoaFisica: string, dsEmail: string, page: number, size: number }>()
);

export const choosePerfisInternoSuccess = createAction(
  '[Perfil Interno] Choose Perfis Interno Success',
  props<{ perfisInterno: PerfisInterno }>()
);

export const choosePerfisInternoFailure = createAction(
  '[Perfil Interno] Choose Perfis Interno Failure',
  props<{ error: any }>()
);

export const loadPerfilInterno = createAction(
  '[Perfil Interno] Load PerfilInterno'
);

export const loadPerfilInternoSuccess = createAction(
  '[Perfil Interno] Load Perfil Interno Success',
  props<{ perfilInterno: PerfilInterno[] }>()
);

export const loadPerfilInternoFailure = createAction(
  '[Perfil Interno] Load Perfil Interno Failure',
  props<{ error: any }>()
);

// Update perfis interno usuario interno actions
export const updatePerfisInternoUsuarioInterno = createAction(
  "[Perfil Interno] Update Perfis Interno Usuario Interno",
  props<{ perfisInternoColaboradorRequest: PerfisInternoColaboradorRequest }>()
);
export const updatePerfisInternoUsuarioInternoSuccess = createAction(
  "[Perfil Interno] Update Perfis Interno Usuario Interno Success",
  props<{ result: string }>()
);
export const updatePerfisInternoUsuarioInternoFailure = createAction(
  "[Perfil Interno] Update Perfis Interno Usuario Interno Failure",
  props<{ error: any }>()
);

export const setUpdatedPerfilInterno = createAction(
  '[Perfil Interno] Set Updated',
  props<{ updated: boolean }>()
);

export const clearPerfisInterno = createAction(
  "[Perfil Interno] Clear Perfis Interno"
);






