import { Injectable } from "@angular/core";
import { MatDateFormats, NativeDateAdapter } from "@angular/material/core";

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
  // Override the format method to customize the date format
  override format(date: Date, displayFormat: string): string {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    switch (displayFormat) {
      case "DD/MM/YYYY": {
        return `${day}/${month}/${year}`;
      }
      case "MMM": {
        return date.toLocaleString("pt-br", { month: "short" });
      }
      case "MMM YYYY": {
        const shortMonth = date.toLocaleString("pt-br", { month: "short" });
        return `${shortMonth} ${year}`;
      }
      case "MMMM YYYY": {
        const fullMonth = date.toLocaleString("pt-br", { month: "long" });
        return `${fullMonth} ${year}`;
      }
      default: {
        // Default to DD/MM/YYYY if an unknown format is encountered
        return `${day}/${month}/${year}`;
      }
    }
  }

  // Override the parse method to handle various date input formats
  override parse(value: any): Date | null {
    if (typeof value === "string") {
      if (value.includes("/")) {
        const parts = value.split("/");
        if (parts.length === 3) {
          const [day, month, year] = parts;
          return new Date(+year, +month - 1, +day);
        }
      } else if (value.includes("-")) {
        const parts = value.split("-");
        if (parts.length === 3) {
          const [year, month, day] = parts;
          return new Date(+year, +month - 1, +day);
        }
      }
    }
    const timestamp = typeof value === "number" ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }
}

export const MY_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: "DD/MM/YYYY", // Parsing format for date inputs
  },
  display: {
    dateInput: "DD/MM/YYYY", // Display format for date inputs (e.g., in forms)
    monthLabel: "MMM", // Display format for month labels (e.g., in the calendar header)
    monthYearLabel: "MMM YYYY", // Display format for month and year labels (e.g., in date pickers)
    dateA11yLabel: "DD/MM/YYYY", // Accessibility label for full dates (for screen readers)
    monthYearA11yLabel: "MMMM YYYY", // Accessibility label for month and year (for screen readers)
  },
};
