import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./util/app.guard";
import { perfilResolver } from "./resolver/perfilResolver";
import { ColaboradorVincCnpjComponent } from "./colaborador-vinc-cnpj/colaborador-vinc-cnpj.component";
import { IntegradorComponent } from "./integrador/integrador.component";
import { LoginComponent } from "./login/login.component";
import { LogComponent } from "./log/log.component";
import { HomeComponent } from "./home/home.component";
import { GestaoPerfilColabInterComponent } from "./gestao-perfil-colab-inter/gestao-perfil-colab-inter.component";

const routes: Routes = [
  { path: "login", component: LoginComponent,  data: { breadcrumb: "Login" }, },
  { path: "", redirectTo: "/home", pathMatch: "full" },
  {
    path: "home",
    component: HomeComponent,
    data: { breadcrumb: "Home" },
    canActivate: [AuthGuard],
    resolve: { perfis: perfilResolver },
    children: [],
  },
  {
    path: "gestao-perfil-colab-inter",
    component: GestaoPerfilColabInterComponent,
    data: { breadcrumb: "Gestão de Perfil para Colaborador Interno Anvisa" },
    canActivate: [AuthGuard],
    resolve: { perfis: perfilResolver },
    children: [],
  },
  {
    path: "colab-vinc-cnpj-cnes",
    component: ColaboradorVincCnpjComponent,
    data: { breadcrumb: "Colaboradores vinculados a um CNPJ ou CNES" },
    canActivate: [AuthGuard],
    resolve: { perfis: perfilResolver },
    children: [],
  }, 
  {
    path: "log",
    component: LogComponent,
    data: { breadcrumb: "Auditoria" },
    canActivate: [AuthGuard],
    resolve: { perfis: perfilResolver },
  },
  {
    path: "integrador",
    component: IntegradorComponent,
    data: { breadcrumb: "Integração de outros sistemas com o Cadastro Anvisa" },
    canActivate: [AuthGuard],
    resolve: { perfis: perfilResolver },
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
