import { Component } from '@angular/core';

@Component({
  selector: 'app-header-not-logged',
  templateUrl: './header-not-logged.component.html',
  styleUrls: ['./header-not-logged.component.scss']
})
export class HeaderNotLoggedComponent {

}
