import { Component, Inject, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Observable, debounceTime, distinctUntilChanged, map, startWith } from "rxjs";
import { Perfil } from "src/app/model/perfil.model";
import { Sistema } from "src/app/model/sistema.model";
import { SistemaPerfisColaboradorRequest } from "src/app/model/sistemaperfiscolaboradorrequest.model";
import { ColaboradorService } from "src/app/service/colaborador.service";
import { PerfilService } from "src/app/service/perfil.service";
import { SistemaService } from "src/app/service/sistema.service";
import { Constants } from "src/app/util/constants";
import { ToastrFunctions } from "src/app/util/toastr.functions";
import { DialogSelectEmpresaComponent } from "../dialog-select-empresa/dialog-select-empresa.component";
import { MatSelectChange } from "@angular/material/select";
import { Functions } from "src/app/util/functions";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: "app-dialog-add-perfil-sistema-colab",
  templateUrl: "./dialog-add-perfil-sistema-colab.component.html",
})
export class DialogAddPerfilSistemaColabComponent implements OnInit {
  controlSistema = new FormControl<string | Sistema>("");
  optionsSistema: Sistema[] = [];
  filteredOptionsSistema!: Observable<Sistema[]>;
  perfis: Perfil[] = [];
  idUsuarioRepresentante!: string;
  idsPerfis!: string[];
  coSistema!: string;
  showSpinner = false;
  cnpjsOrigem!: string[];
  cpfOrigem!: string;
  dialogSelectEmpresa: any;
  cpf!: string;

  constructor(
    public sistemaService: SistemaService,
    public perfilService: PerfilService,
    public colaboradorService: ColaboradorService,
    public activatedRoute: ActivatedRoute,
    public toastr: ToastrService,
    public dialogRef: MatDialogRef<DialogAddPerfilSistemaColabComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {

    const usuarioLogado = Functions.getUsuarioLogadoFromSession();
    this.cpf = usuarioLogado.preferredUsername;

    this.idUsuarioRepresentante = this.data.idUsuarioRepresentante;
    this.findSistemaSolicita();
    this.filteredOptionsSistema = this.controlSistema.valueChanges.pipe(
      startWith(""),
      debounceTime(300), // Wait for 300ms pause in events
      distinctUntilChanged(), // Only emit when the current value is different from the last
      map((value) => {
        const name = typeof value === "string" ? value : value?.sgSistema;
        return name
          ? this._filterSistema(name as string)
          : this.optionsSistema.slice();
      })
    );
    
  }

  displayFnSistema(sistema: Sistema): string {
    return sistema && sistema.sgSistema && sistema.noSistema
      ? sistema.sgSistema + " - " + sistema.noSistema
      : "";
  }

  private _filterSistema(name: string): Sistema[] {
    const filterValue = name.toLowerCase();
    return this.optionsSistema.filter((option) =>
      option.sgSistema.toLowerCase().includes(filterValue)
    );
  }

  getSelected(sistema: Sistema) {
    this.coSistema = sistema.id;
    this.findPerfilBySistema();
  }

  reset() {
    this.coSistema = "";
    this.idsPerfis = [];
    this.controlSistema.reset();
  }

  findPerfilBySistema() {
    this.showSpinner = true;
    this.perfilService
      .findPerfilBySistema(this.coSistema)
      .pipe()
      .subscribe({
        next: (data: Perfil[]) => {
          if (data) {
            this.perfis = data;
          }
          this.showSpinner = false;
        },
        error: (e: unknown) => {
          ToastrFunctions.showError(
            this.toastr,
            "Algum erro ocorreu ao pesquisar todos os perfis."
          );
          this.showSpinner = false;
        },
      });
  }

  findSistemaSolicita() {
    this.showSpinner = true;
    this.sistemaService
      .findSistemaSolicita()
      .pipe()
      .subscribe({
        next: (data: Sistema[]) => {
          if (data) {
            this.optionsSistema = data;
          }
          this.showSpinner = false;
        },
        error: (e: unknown) => {
          ToastrFunctions.showError(
            this.toastr,
            "Algum erro ocorreu ao pesquisar todos os sistemas."
          );
          this.showSpinner = false;
        },
      });
  }

  selectedGestorCadastros(event: MatSelectChange) {
    const text = event.source.triggerValue;
    if (text === Constants.PERFIL_GESTOR_CADASTROS) {
      this.openDialogSelectEmpresa("0ms", "0ms");
    }
  }

  insereSistemaPerfisUsuario() {
    const request = new SistemaPerfisColaboradorRequest(
      this.idUsuarioRepresentante,
      this.coSistema,
      this.idsPerfis,
      this.cnpjsOrigem,
      this.cpf
    );

    if (
      this.coSistema === null ||
      this.coSistema === undefined ||
      this.idsPerfis === null ||
      this.idsPerfis === undefined
    ) {
      ToastrFunctions.showWarning(
        this.toastr,
        "É necessário selecionar um Sistema e pelo menos um Perfil."
      );
      return;
    }

    this.showSpinner = true;
    this.colaboradorService
      .insereSistemaPerfilUsuario(request)
      .pipe()
      .subscribe({
        next: (data: boolean) => {
          if (data != null && data != undefined) {
            if (data) {
              ToastrFunctions.showInfo(
                this.toastr,
                "Dados inseridos com sucesso."
              );
            } else {
              ToastrFunctions.showWarning(
                this.toastr,
                "Ocorreu algum problema na inserção de sistema / perfil / colaborador."
              );
            }
          }
          this.showSpinner = false;
        },
        error: (error: HttpErrorResponse) => {
          let errorMessage =
            "Algum erro ocorreu ao vincular sistema / perfis ao colaborador.";
          console.log(error);
          if (error.status === 400) {
            errorMessage += ` Detalhes: ${error.error}`;
          }

          ToastrFunctions.showError(this.toastr, errorMessage);

          this.showSpinner = false;
        },
      });
  }

  openDialogSelectEmpresa(
    enterAnimationDuration: string,
    exitAnimationDuration: string
  ): void {
    this.dialogSelectEmpresa = this.dialog.open(DialogSelectEmpresaComponent, {
      width: "60%",
      enterAnimationDuration,
      exitAnimationDuration
    });
    this.dialogSelectEmpresa.afterClosed().subscribe((result: any) => {
      this.cnpjsOrigem = result;
      ToastrFunctions.showInfo(this.toastr, "Dados inseridos com sucesso.");
    });
  }
}
