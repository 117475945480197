import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { environment } from 'src/environments/environment';
import { PessoaJuridica } from '../model/pessoajuridica.model';
import { Observable, map } from 'rxjs';
import { PessoasJuridicas } from '../model/pessoasjuridicas.model';

@Injectable({
  providedIn: 'root'
})
export class PessoaJuridicaService {

  public API = `${environment.API}/api/v1/pessoasjuridicas`;
  private token: unknown;
  
  constructor(
    private httpClient: HttpClient,
    private readonly keycloackService: KeycloakService
  ) {}

  getToken() {
    return this.keycloackService.getToken();
  }
  
  findPessoaJuridicaByCnpj(cnpj: string) {
    this.token = this.getToken();
    const api_key = this.token;
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${api_key}`,
    });
    const requestOptions = { headers: headers};
    return this.httpClient.get<PessoaJuridica>(this.API + '/find-pessoa-juridica-by-cnpj/'+cnpj, requestOptions);
  }

  findPessoaJuridicaById(id: string | null) {
    this.token = this.getToken();
    const api_key = this.token;
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${api_key}`,
    });
    const requestOptions = { headers: headers};
    return this.httpClient.get<PessoaJuridica>(this.API + '/find-pessoa-juridica-by-id/'+id, requestOptions);
  }

  findAllPessoaJuridicaByCpf(): Observable<PessoaJuridica[]> {
    this.token = this.getToken();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.token}`,
    });

    const requestOptions = { headers: headers };
    const url = `${this.API}/find-all-pessoa-juridica-by-cpf`;
    
    return this.httpClient.get<PessoaJuridica[]>(url, requestOptions);
  }

  updateEmpresas(cnpjs: string[]): Observable<any> {
    this.token = this.getToken();
    const api_key = this.token;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${api_key}`,
    });
    const requestOptions = { headers: headers };

    const API_URL = `${this.API}/atualiza-empresas`;
    const body = { cnpjs: cnpjs }; // Sending CNPJs in the request body

    return this.httpClient.put(API_URL, body, requestOptions);
  }

  findInstituicoesByCnes(cnes: string | undefined, page: number, size: number) {
    this.token = this.getToken();
    const api_key = this.token;
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${api_key}`,
    });
    const requestOptions = { headers: headers };
    return this.httpClient
      .get<PessoasJuridicas>(
        this.API +
          "/find-instituicoes-by-cnes/" +
          cnes +
          "?page=" +
          page +
          "&size=" +
          size,
        requestOptions
      )
      .pipe(map((res: any) => res));
  }

}
