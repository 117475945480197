<h2 mat-dialog-title>
  {{ isEditMode ? "Edição da Integração" : "Criação da Integração" }}
</h2>
<form [formGroup]="keycloakForm" (ngSubmit)="onSubmit()">
  <mat-dialog-content class="dialog-content">
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>Client ID</mat-label>
      <input matInput formControlName="clientId" />
      <mat-error *ngIf="keycloakForm.get('clientId')?.hasError('required')"
        >Client ID é obrigatório</mat-error
      >
    </mat-form-field>

    <mat-form-field appearance="outline" class="full-width">
      <mat-label>Nome</mat-label>
      <input matInput formControlName="name" />
      <mat-error *ngIf="keycloakForm.get('name')?.hasError('required')"
        >Nome é obrigatório</mat-error
      >
    </mat-form-field>

    <mat-form-field appearance="outline" class="full-width">
      <mat-label>Descrição</mat-label>
      <input matInput formControlName="description" />
    </mat-form-field>

    <mat-form-field appearance="outline" class="full-width">
      <mat-label>Root URL</mat-label>
      <input matInput formControlName="rootUrl" />
      <mat-error *ngIf="keycloakForm.get('rootUrl')?.hasError('required')"
        >Root URL é obrigatório</mat-error
      >
    </mat-form-field>

    <mat-form-field appearance="outline" class="full-width">
      <mat-label>Base URL</mat-label>
      <input matInput formControlName="baseUrl" />
      <mat-error *ngIf="keycloakForm.get('baseUrl')?.hasError('required')"
        >Base URL é obrigatório</mat-error
      >
    </mat-form-field>

    <mat-form-field appearance="outline" class="full-width">
      <mat-label>Admin URL</mat-label>
      <input matInput formControlName="adminUrl" />
    </mat-form-field>

    <div formArrayName="redirectUris">
      <ng-container
        *ngFor="let uriControl of redirectUris.controls; let i = index"
      >
        <div [formGroupName]="i" class="flex-container">
          <mat-form-field appearance="outline" class="full-width">
            <input
              matInput
              formControlName="uri"
              placeholder="Entre com a Redirect URI"
            />
          </mat-form-field>
          <mat-icon class="delete-btn" (click)="deleteRedirectUri(i)"
            >delete_forever</mat-icon
          >
        </div>
      </ng-container>
      <button class="add-btn" mat-mini-fab (click)="addRedirectUri()">
        <mat-icon>add</mat-icon>
      </button>
    </div>

    <div formArrayName="webOrigins">
      <ng-container
        *ngFor="let originControl of webOrigins.controls; let i = index"
      >
        <div [formGroupName]="i" class="flex-container">
          <mat-form-field appearance="outline" class="full-width">
            <input
              matInput
              formControlName="origin"
              placeholder="Entre com a Web Origin"
            />
          </mat-form-field>
          <mat-icon class="delete-btn" (click)="deleteWebOrigin(i)"
            >delete_forever</mat-icon
          >
        </div>
      </ng-container>
      <button class="add-btn" mat-mini-fab (click)="addWebOrigin()">
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-button mat-dialog-close>Cancel</button>
    <button
      mat-button
      color="primary"
      [disabled]="!keycloakForm.valid || !hasFormChanged()"
      type="submit"
    >
      {{ isEditMode ? "Atualizar" : "Salvar" }}
    </button>
  </mat-dialog-actions>
</form>
