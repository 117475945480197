import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { NavigationEnd, Router } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { Observable, Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";

import { AppState } from "src/app/interfaces/app-state.interface";
import { Constants } from "src/app/util/constants";
import { Functions } from "src/app/util/functions";
import { ToastrFunctions } from "src/app/util/toastr.functions";
import { Perfil } from "src/app/model/perfil.model";
import { PessoaJuridica } from "src/app/model/pessoajuridica.model";

import { loadCompany, loadAllCompanies } from "src/app/actions/company.action";
import {
  checkWritePermission,
  setSelectedProfile,
  logout,
} from "src/app/actions/auth.actions";
import { selectAllCompanies } from "src/app/selectors/company.selector";
import { selectPerfis } from "src/app/selectors/auth.selectors";

import { ResetService } from "src/app/service/reset.service";
import { HomeService } from "src/app/service/home.service";
import { EnableOrgService } from "src/app/service/enableorg.service";
import { EnableTxtNoPessoaFisicaService } from "src/app/service/enabletxtnopessoafisica.service";
import { loadAllEmployees } from "src/app/actions/employee.action";
import { UsuarioLogado } from "src/app/model/usuariologado.model";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {
  allCompanies$!: Observable<PessoaJuridica[]>;
  selectedIdPessoaJuridica = new FormControl("");
  unsubscribe$ = new Subject<void>();
  username!: string;
  perfis: Perfil[] = [];
  isDropdownOpen = false;
  isShowSelectEmpresa = false;
  isHomePage = false;
  selectedPerfil = "";
  private resetSubscription!: Subscription;

  constructor(
    private store: Store<AppState>,
    private toastr: ToastrService,
    private router: Router,
    private resetService: ResetService,
    private homeService: HomeService,
    private enableOrgService: EnableOrgService,
    private enableTxtNoPessoaFisicaService: EnableTxtNoPessoaFisicaService
  ) {}

  ngOnInit(): void {
    const usuarioLogado = Functions.getUsuarioLogadoFromSession();

    this.setupCompanySelection();
    this.initializeUser(usuarioLogado);
    this.subscribeToRouterEvents();
    this.subscribeToResetEvents();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    if (this.resetSubscription) {
      this.resetSubscription.unsubscribe();
    }
  }

  ngAfterViewInit() {
    const isNotAdmin = !this.isAdminPerfil(this.selectedPerfil);

    if (this.selectedIdPessoaJuridica.value && isNotAdmin) {
      this.loadCompanyData(this.selectedIdPessoaJuridica.value);
    }
  }

  onToggleChangePerfil(perfil: string) {
    this.store.dispatch(loadAllCompanies());
    const usuarioLogado = Functions.getUsuarioLogadoFromSession();
    usuarioLogado.selectedProfile = perfil;
    Functions.setUsuarioLogadoIntoSession(usuarioLogado);

    this.store.dispatch(setSelectedProfile({ profile: perfil }));

    if (this.isHomePage) {
      this.resetService.triggerReset();
    }

    this.homeService.triggerHome();
    this.enableOrgService.triggerEnableOrg();
    this.enableTxtNoPessoaFisicaService.triggerEnableTxtNoPessoaFisica();
  }

  isAdminPerfil(perfil: string) {
    return [
      Constants.PERFIL_GESTOR_ANVISA,
      Constants.PERFIL_ADMINISTRADOR,
      Constants.PERFIL_GESTOR_ANVISA_CONSULTA,
    ].includes(perfil);
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  logout() {
    this.store.dispatch(logout());
  }

  private initializeUser(usuarioLogado: UsuarioLogado) {
    this.username = usuarioLogado.givenName;
    this.getPerfisFromDb();
    this.setInitialPerfil(usuarioLogado);
  }

  private setInitialPerfil(usuarioLogado: UsuarioLogado) {
    if (usuarioLogado.selectedProfile) {
      this.selectedPerfil = usuarioLogado.selectedProfile;
      this.onToggleChangePerfil(this.selectedPerfil);
    } else {
      this.setPerfilBasedOnPriority(usuarioLogado);
    }
  }

  private subscribeToRouterEvents() {
    this.router.events.pipe(takeUntil(this.unsubscribe$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isHomePage = event.urlAfterRedirects === Constants.HOME_PAGE;
        const usuarioLogado = Functions.getUsuarioLogadoFromSession();
        if (usuarioLogado.selectedProfile) {
          this.onToggleChangePerfil(usuarioLogado.selectedProfile);
        }
      }
    });
  }

  private setupCompanySelection() {
    this.allCompanies$ = this.store.pipe(select(selectAllCompanies));

    this.allCompanies$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((companies) => {
      const isSingleCompany = companies?.length === 1;
      const savedIdPessoaJuridica = sessionStorage.getItem("selectedIdPessoaJuridica");
      const isNotAdmin = !this.isAdminPerfil(this.selectedPerfil);
      let newIdPessoaJuridica = "";

      if (isSingleCompany && isNotAdmin) {
        // Automatically select the only available company
        if(companies[0].id) {
          newIdPessoaJuridica = companies[0].id;
        }
      } else if (savedIdPessoaJuridica && isNotAdmin) {
        // Select the previously saved company
        newIdPessoaJuridica = savedIdPessoaJuridica;
      }

      if (newIdPessoaJuridica !== this.selectedIdPessoaJuridica.value) {
        this.selectedIdPessoaJuridica.setValue(newIdPessoaJuridica);
      }

      this.isShowSelectEmpresa =
        Boolean(companies?.length) && !this.isHomePage && isNotAdmin;
    });

    this.selectedIdPessoaJuridica.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((idPessoaJuridica) => {
        if (idPessoaJuridica) {
          this.loadCompanyData(idPessoaJuridica);
        }
      });
  }

  private loadCompanyData(idPessoaJuridica: string) {
    this.store.dispatch(loadCompany({ idPessoaJuridica }));
    sessionStorage.setItem("selectedIdPessoaJuridica", idPessoaJuridica);
    this.store.dispatch(loadAllEmployees({ idPessoaJuridica }));
    this.store.dispatch(
      checkWritePermission({ idPessoaRepresentada: idPessoaJuridica })
    );
  }

  private subscribeToResetEvents() {
    this.resetSubscription = this.resetService.reset$.subscribe(() => {
      this.resetAll();
    });
  }

  private getPerfisFromDb() {
    this.store
      .pipe(select(selectPerfis), takeUntil(this.unsubscribe$))
      .subscribe({
        next: (perfis) => {
          if (perfis && perfis.length > 0) {
            this.perfis = perfis;
            this.setSelectedPerfil();
          }
        },
        error: () => {
          ToastrFunctions.showError(
            this.toastr,
            "Algum erro ocorreu ao pesquisar Perfis."
          );
        },
      });
  }

  private setSelectedPerfil() {
    const usuarioLogado = Functions.getUsuarioLogadoFromSession();
    if (usuarioLogado.selectedProfile) {
      this.selectedPerfil = usuarioLogado.selectedProfile;
    } else {
      this.setPerfilBasedOnPriority(usuarioLogado);
    }
  }

  private setPerfilBasedOnPriority(usuarioLogado: any) {
    const perfilPriorities = [
      Constants.PERFIL_GESTOR_ANVISA,
      Constants.PERFIL_RESPONSAVEL_LEGAL,
      Constants.PERFIL_GESTOR_CADASTROS,
      Constants.PERFIL_GESTOR_ANVISA_CONSULTA,
      Constants.PERFIL_ADMINISTRADOR,
    ];

    for (const priority of perfilPriorities) {
      const perfil = this.perfis.find((p) => p.noPerfil === priority);
      if (perfil) {
        this.selectedPerfil = perfil.noPerfil;
        usuarioLogado.selectedProfile = this.selectedPerfil;
        Functions.setUsuarioLogadoIntoSession(usuarioLogado);
        this.store.dispatch(
          setSelectedProfile({ profile: this.selectedPerfil })
        );
        break;
      }
    }
  }

  private resetAll() {
    this.selectedIdPessoaJuridica.setValue("");
    sessionStorage.removeItem("selectedIdPessoaJuridica");
    this.store.dispatch(loadAllCompanies());
  }
}
