<h1 mat-dialog-title>Editar Perfil de Colaborador Interno Anvisa</h1>
<div mat-dialog-content *ngIf="selectedInternalUser$ | async as selectedInternalUser">
  <div class="main-content" id="main-content">
    <div>
      <fieldset>
        <div class="row">
          <div class="col-md-12">
            <div class="card-custom-1">
              Colaborador(a) {{selectedInternalUser.noPessoaFisica}}<br />
              CPF {{maskCpf(selectedInternalUser.nuCpf)}}
            </div>
          </div>
          <div class="col-md-12">
            <div style="width: 50%;">
              <div style="font-size: 14px; margin-top: 10px;">
                <strong>Ativo</strong>
              </div>
              <div class="column container-vertical-align" style="margin-top: 10px;">
                <div>
                  <mat-slide-toggle 
                    id="activate-user" 
                    color="primary"
                    [(ngModel)]="active">
                  </mat-slide-toggle>                    
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div style="margin-top: 20px;">
              <div style="font-size: 14px"><strong>Perfis</strong></div>
            </div>
            <div class="row">
              <div class="column" style="width: 100%;">
                <mat-form-field appearance="fill" class="custom-field custom-width">
                  <mat-label>Selecione um Perfil</mat-label>
                  <mat-select [formControl]="perfis" [(ngModel)]="selectedPerfis" multiple>
                    <mat-option *ngFor="let perfil of perfilList" [value]="perfil">{{perfil.noPerfil}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    </div>

  </div>
</div>
<div mat-dialog-actions align="center" style="margin-bottom: 15px;">
  <button class="button input-button-custom" (click)="update()">Salvar</button>
  <button class="button input-button-white-custom" mat-dialog-close>Cancelar</button>
</div>