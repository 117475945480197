import { Component, Inject, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { ToastrService } from "ngx-toastr";
import { PessoaJuridica } from "src/app/model/pessoajuridica.model";
import { PessoasJuridicas } from "src/app/model/pessoasjuridicas.model";
import { PessoaJuridicaService } from "src/app/service/pessoajuridica.service";
import { Functions } from "src/app/util/functions";
import { ToastrFunctions } from "src/app/util/toastr.functions";

@Component({
  selector: "app-dialog-search-instituicao",
  templateUrl: "./dialog-search-instituicao.component.html",
})
export class DialogSearchInstituicaoComponent {
  @ViewChild("paginatorPageSize")
  paginatorPageSize!: MatPaginator;

  pageSizes = [20, 50, 100];

  totalElements = 0;

  displayedColumns = ["id", "cnpj", "cnes", "razaoSocial"];
  dataSourceWithPageSize = new MatTableDataSource<PessoaJuridica>();

  pessoasJuridicas: PessoaJuridica[] = [];

  pessoaJuridica!: PessoaJuridica;

  cnes = "";

  constructor(
    public pessoaJuridicaService: PessoaJuridicaService,
    public toastr: ToastrService,
    public dialogRef: MatDialogRef<DialogSearchInstituicaoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.cnes = this.data.cnes;
  }

  findInstituicoesByCnes(page: number, size: number) {
    if (!this.cnes.trim()) {
      ToastrFunctions.showWarning(this.toastr, "Preencha o CNES.");
      return;
    }

    this.pessoaJuridicaService
      .findInstituicoesByCnes(this.cnes, page, size)
      .pipe()
      .subscribe({
        next: (data: PessoasJuridicas) => {
          if (data && !data.empty) {
            this.pessoasJuridicas = data["content"];
            this.totalElements = data["totalElements"];
            this.dataSourceWithPageSize =
              new MatTableDataSource<PessoaJuridica>(this.pessoasJuridicas);
          } else {
            ToastrFunctions.showInfo(
              this.toastr,
              "A consulta está sendo processada, por favor, tente novamente após alguns segundos."
            );
          }
        },
        error: (e: unknown) => {
          ToastrFunctions.showError(
            this.toastr,
            "Algum erro ocorreu ao pesquisar instituições por CNES."
          );
        },
      });
  }

  selecionaInstituicao(pessoaJuridica: PessoaJuridica) {
    this.dialogRef.close(pessoaJuridica);
  }

  nextPage(event: PageEvent) {
    this.findInstituicoesByCnes(event.pageIndex, event.pageSize);
  }

  reset() {
    this.cnes = "";
    this.pessoasJuridicas = [];
  }

  formatCnpj(str: string) {
    return Functions.formatCnpj(str);
  }

}
