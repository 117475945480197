// perfis-interno.reducer.ts
import { createReducer, on } from "@ngrx/store";
import * as PerfilInternoActions from "../actions/perfilinterno.action";
import { clearPerfisInterno } from "../actions/perfilinterno.action";
import { PerfilInternoState } from "../interfaces/perfilinterno-state.interface";

export const initialState: PerfilInternoState = {
  perfisInterno: null,
  perfilInterno: [],
  isLoaded: { choosePerfisInterno: true },
  updated: false,
  error: undefined,
};

export const perfilInternoReducer = createReducer(
  initialState,
  on(PerfilInternoActions.choosePerfisInterno, (state) => ({
    ...state,
    isLoaded: { ...state.isLoaded, choosePerfisInterno: false },
    error: null,
  })),

  on(
    PerfilInternoActions.choosePerfisInternoSuccess,
    (state, { perfisInterno }) => ({
      ...state,
      perfisInterno,
      isLoaded: { ...state.isLoaded, choosePerfisInterno: true },
      error: null,
    })
  ),
  on(PerfilInternoActions.choosePerfisInternoFailure, (state, { error }) => ({
    ...state,
    error,
    isLoaded: { ...state.isLoaded, choosePerfisInterno: true },
  })),

  on(PerfilInternoActions.loadPerfilInterno, (state) => ({
    ...state,
    isLoaded: { ...state.isLoaded, loadPerfilInterno: false },
  })),
  on(
    PerfilInternoActions.loadPerfilInternoSuccess,
    (state, { perfilInterno }) => ({
      ...state,
      isLoaded: { ...state.isLoaded, loadPerfilInterno: true },
      perfilInterno,
    })
  ),
  on(PerfilInternoActions.loadPerfilInternoFailure, (state, { error }) => ({
    ...state,
    isLoaded: { ...state.isLoaded, loadPerfilInterno: true },
    error,
  })),

  on(PerfilInternoActions.updatePerfisInternoUsuarioInterno, (state) => ({
    ...state,
    isLoaded: { ...state.isLoaded, updatePerfisInternoUsuarioInterno: false },
    updated: false,
  })),
  on(
    PerfilInternoActions.updatePerfisInternoUsuarioInternoSuccess,
    (state) => ({
      ...state,
      updated: true,
      isLoaded: { ...state.isLoaded, updatePerfisInternoUsuarioInterno: true },
    })
  ),
  on(
    PerfilInternoActions.updatePerfisInternoUsuarioInternoFailure,
    (state, { error }) => ({
      ...state,
      error,
      isLoaded: { ...state.isLoaded, insereColaborador: true },
    })
  ),

  on(PerfilInternoActions.setUpdatedPerfilInterno, (state, { updated }) => ({
    ...state,
    updated,
  })),

  on(clearPerfisInterno, () => initialState)
);
