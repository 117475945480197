import { createSelector, createFeatureSelector } from '@ngrx/store';
import { createIsLoadedSelector } from './genericselector.interface';
import { LogState } from '../interfaces/log-state.interface';

export const selectLogState = createFeatureSelector<LogState>('logs');

export const selectAllLogs = createSelector(
  selectLogState,
  state => state.logs
);

export const getLogsIsLoaded =  createIsLoadedSelector(selectLogState);