<h1 mat-dialog-title>Pesquisar Organização com CNES</h1>
<div mat-dialog-content>
    <div class="main-content" id="main-content">
      <div class="row">
        <div>
          <fieldset>            
            <div class="row">
              <div class="col-md-12">
                <div class="br-input">
                  <label for="cnpj">CNES</label>
                  <input id="cnpj" [(ngModel)]="cnes" type="text" placeholder="Preencha o CNES" mask="0000000"/>
                </div>
              </div>                         
            </div>
            <div mat-dialog-actions style="margin-bottom: 15px;">
              <button cdkFocusInitial (click)="findInstituicoesByCnes(0,20)" class="button input-button-custom">Pesquisar</button>
              <button mat-dialog-close class="button input-button-white-custom">Cancelar</button>
            </div>
            <div class="row" *ngIf="pessoasJuridicas.length > 0">
              <div class="br-input" style="margin-bottom: 8px;">
                <label for="cnpj">Instituições sob o CNES {{cnes}}</label>
              </div>
              <div class="mat-elevation-z8">
                <mat-table #table [dataSource]="dataSourceWithPageSize">
                  
                  <ng-container matColumnDef="id">
                    <mat-header-cell *matHeaderCellDef> Selecione </mat-header-cell>
                    <mat-cell *matCellDef="let pessoaJuridica"> 
                      <div style="padding-left: 24px !important; width: 100%;">
                        <input type="radio" id="id_{{pessoaJuridica.id}}" name="id" value="{{pessoaJuridica.id}}" (click)="selecionaInstituicao(pessoaJuridica)">
                      </div> 
                    </mat-cell>                      
                  </ng-container>

                  <ng-container matColumnDef="cnpj">
                    <mat-header-cell *matHeaderCellDef> CNPJ </mat-header-cell>
                    <mat-cell *matCellDef="let pessoaJuridica"> {{formatCnpj(pessoaJuridica.numeroCNPJ)}} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="cnes">
                    <mat-header-cell *matHeaderCellDef> CNES </mat-header-cell>
                    <mat-cell *matCellDef="let pessoaJuridica"> {{pessoaJuridica.codigoCnes}} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="razaoSocial">
                    <mat-header-cell *matHeaderCellDef> Razão Social </mat-header-cell>
                    <mat-cell *matCellDef="let pessoaJuridica"> {{pessoaJuridica.razaoSocial}} </mat-cell>
                  </ng-container>
                                            
                  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                
                </mat-table>
                <mat-paginator
                  #paginatorPageSize
                  [pageSizeOptions]="pageSizes"
                  [length]="totalElements" (page)="nextPage($event)" 
                  showFirstLastButtons
                ></mat-paginator>
              
              </div>
            </div>
          </fieldset>
        </div>        
      </div>    
  </div>
</div>




    
 

