import { createSelector, createFeatureSelector } from '@ngrx/store';
import { createIsLoadedSelector } from './genericselector.interface';
import { PerfilState } from '../interfaces/perfil-state.interface';

export const selectPerfilFeature = createFeatureSelector<PerfilState>('perfis');

export const selectAllPerfis = createSelector(
  selectPerfilFeature,
  (state: PerfilState) => state.perfis
);

export const selectAllNode = createSelector(
  selectPerfilFeature,
  (state: PerfilState) => state.perfisByNode
);

export const selectPerfilError = createSelector(
  selectPerfilFeature,
  (state: PerfilState) => state.error
);

// Factory function to select profiles by node ID
export const selectProfilesByNodeId = (nodeId: string) => createSelector(
  selectPerfilFeature,
  (state: PerfilState) => state.perfisByNode[nodeId]
);

export const selectColaboradoresProfilesByNodeId = (usuarioRepresentante: string, nodeId: string) => createSelector(
  selectPerfilFeature,
  (state: PerfilState) =>
    state.perfisByUsuarioRepresentanteId[usuarioRepresentante]?.[nodeId]
);

export const selectAllColaboradoresProfilesByNodeId = createSelector(
  selectPerfilFeature,
  (state: PerfilState) =>
    state.perfisByUsuarioRepresentanteId
);


export const getPerfilIsLoaded =  createIsLoadedSelector(selectPerfilFeature);
