import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EnableTxtNoPessoaFisicaService {
  private enableTxtNoPessoaFisicaSubject = new Subject<void>();

  enableTxtNoPessoaFisicaSubject$ = this.enableTxtNoPessoaFisicaSubject.asObservable();

  triggerEnableTxtNoPessoaFisica() {
    this.enableTxtNoPessoaFisicaSubject.next();
  }
}
