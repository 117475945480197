export class SituacaoCadastralSerpro {
  codigo: string;
  data: string;
  motivo: string;

  /**
   * Construtor da classe.
   * Inicializa a instância com os dados fornecidos ou valores padrão.
   * @param {Partial<SituacaoCadastralSerpro>} dados - Dados opcionais para inicializar as propriedades da instância.
   */
  constructor(dados?: Partial<SituacaoCadastralSerpro>) {
    this.codigo = dados?.codigo ?? '';
    this.data = dados?.data ?? '';
    this.motivo = dados?.motivo ?? '';
  }

  /**
   * Retorna a descrição para um dado 'Código da Situação Cadastral' (CSC).
   * @returns {string} - A descrição correspondente para o código.
   */
  getSituacaoCadastralDesc(): string {
    if (this.codigo == null) {
      return "Código não fornecido";
    }

    const cscDesc: Record<string, string> = {
      "1": "Nula",
      "2": "Ativa",
      "3": "Suspensa",
      "4": "Inapta",
      "5": "Ativa Não Regular",
      "8": "Baixada",
    };

    if (!(this.codigo in cscDesc)) {
      return `${this.codigo} (Código desconhecido)`;
    }

    return `${this.codigo} (${cscDesc[this.codigo]})`;
  }
}
