import { Component } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  constructor(
    private keycloakService: KeycloakService,
    private route: ActivatedRoute  ) {}

  async onLogin(): Promise<void> {
    try {
      const redirectUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
      // Redirect to the Keycloak login page and include the redirectUrl
      await this.keycloakService.login({
        redirectUri: window.location.origin + redirectUrl,
        idpHint: 'govbr'
      });
    } catch (error) {
      console.error('An error occurred during the login process:', error);
    }
  }
}

