<div class="login-page-container" style="margin-top: 20px;">
  <mat-card class="login-card">
    <mat-card-header>
      <mat-card-title>
        <mat-icon class="login-icon">person_add</mat-icon>
        Novo Cadastro Anvisa
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <button class="gov-br-button" (click)="onLogin()">
        Entrar com <span class="gov-br-bold">gov.br</span>
      </button>

      <p class="info-text">
        Clique no botão acima para entrar no Novo Cadastro Anvisa ou cadastrar
        sua senha de acesso.
      </p>
      <p class="caution-text">
        Não forneça sua senha para outra pessoa. Ela é individual e
        intransferível.
      </p>
    </mat-card-content>
  </mat-card>
</div>
