import { createAction, props } from '@ngrx/store';
import { PessoaJuridica } from '../model/pessoajuridica.model';
import { GovBrEmpresa } from '../model/govbrempresa';
import { Porte } from '../model/porte.model';

// Action for selecting a company by its ID
export const loadCompany = createAction(
  '[Company] Choose Company', 
  props<{ idPessoaJuridica: string | null }>()
);

// Successfully loaded a company
export const loadCompanySuccess = createAction(
  '[Company] Load a Company Success', 
  props<{ company: PessoaJuridica }>()
);

// Handles failures in loading a company
export const loadCompanyFailure = createAction(
  '[Company] Load a Company Failure', 
  props<{ error: string }>() // Consider using a more specific type than 'any' for the error
);

// Initiates the process of loading all companies
export const loadAllCompanies = createAction(
  '[Company] Load All Companies'
);

// Successfully loaded all companies
export const loadAllCompaniesSuccess = createAction(
  '[Company] Load All Companies Success', 
  props<{ companies: PessoaJuridica[] }>()
);

// Handles failures in loading all companies
export const loadAllCompaniesFailure = createAction(
  '[Company] Load All Companies Failure', 
  props<{ error: string }>() // Consider using a more specific type than 'any' for the error
);

export const loadEmpresas = createAction(
  '[Company] Load Gov Br Empresas',
  props<{ govBrAccessToken: string }>()
);

export const loadEmpresasSuccess = createAction(
  '[Company] Load Gov Br Empresas Success',
  props<{ empresas: GovBrEmpresa[] }>()
);

export const loadEmpresasFailure = createAction(
  '[Company] Load Gov Br Empresas Failure',
  props<{ error: any }>()
);

export const updateEmpresas = createAction(
  '[Company] Update Company',
  props<{ cnpjs: string[]}>()
);

export const updateEmpresasSuccess = createAction(
  '[Company] Update Company Success'
);

export const updateEmpresasFailure = createAction(
  '[Company] Update Company Failure',
  props<{ error: any }>()
);

export const consultaCNPJ = createAction(
  '[Company] Consulta CNPJ Serpro',
  props<{ cnpj: string }>()
);

export const consultaCNPJSuccess = createAction(
  '[Company] Consulta CNPJ Serpro Success',
  props<{ data: any }>()
);

export const consultaCNPJFailure = createAction(
  '[Company] Consulta CNPJ Serpro Failure',
  props<{ error: any }>()
);

export const consultaPorte = createAction(
  '[Company] Consulta Porte',
  props<{ codigoPorte: number }>()
);

export const consultaPorteSuccess = createAction(
  '[Company] Consulta Porte Success',
  props<{ porte: Porte }>()
);

export const consultaPorteFailure = createAction(
  '[Company] Consulta Porte Failure',
  props<{ error: any }>()
);

export const clearPorte = createAction(
  '[Company] Clear Porte'
);

export const clearCompany = createAction(
  '[Company] Clear Company'
);

export const clearSerproCompany = createAction(
  '[Company] Clear Serpro Company'
);
