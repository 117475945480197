import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Functions } from "../util/functions";
import { Observable, Subject, Subscription, takeUntil } from "rxjs";
import { BaseComponent } from "../util/base.component";
import { MatDialog } from "@angular/material/dialog";
import { DialogAddColaboradorComponent } from "./dialog-add-colaborador/dialog-add-colaborador.component";
import { DialogColabAtrPerfilComponent } from "./dialog-colab-atr-perfil/dialog-colab-atr-perfil.component";
import { PessoaJuridicaSerpro } from "../model/pessoajuridicaserpro";
import { DialogSearchEmpresaComponent } from "./dialog-search-empresa/dialog-search-empresa.component";
import { PessoaJuridica } from "../model/pessoajuridica.model";
import { DialogSearchInstituicaoComponent } from "./dialog-search-instituicao/dialog-search-instituicao.component";
import { MatTreeNestedDataSource } from "@angular/material/tree";
import { NestedTreeControl } from "@angular/cdk/tree";
import { SistemaPerfilNode } from "../model/sistemaperfilnode";
import { DialogColabAtrPerfilLoteComponent } from "./dialog-colab-atr-perfil-lote/dialog-colab-atr-perfil-lote.component";
import { Store, select } from "@ngrx/store";
import {
  selectCompany,
  selectCompanyPorte,
  selectPessoaJuridicaSerpro,
} from "../selectors/company.selector";
import {
  selectAllEmployees,
  selectAllFilteredEmployees,
  selectAllSelected,
  selectIsFiltered,
  selectIsUpdatedEmployee,
} from "../selectors/employee.selector";
import { ColaboradorCustom } from "../model/colaboradorcustom";
import {
  clearCompany,
  clearPorte,
  clearSerproCompany,
  consultaCNPJ,
  consultaPorte,
  loadCompany,
} from "../actions/company.action";
import {
  chooseEmployees,
  loadAllEmployees,
  setFiltered,
  setUpdatedEmployee,
  toggleAllEmployees,
  toggleEmployee,
} from "../actions/employee.action";
import { ToastrFunctions } from "../util/toastr.functions";
import { environment } from "src/environments/environment";
import { AppState } from "../interfaces/app-state.interface";
import {
  selectHasWritePermission,
  selectPerfis,
  selectProfile,
} from "../selectors/auth.selectors";
import {
  checkWritePermission,
  setWritePermission,
} from "../actions/auth.actions";
import { SituacaoCadastralSerpro } from "../model/situacaocadastralserpro";
import { ResetService } from "../service/reset.service";
import { EnableOrgService } from "../service/enableorg.service";
import { Constants } from "../util/constants";
import { Porte } from "../model/porte.model";

@Component({
  selector: "app-colaborador-vinc-cnpj",
  templateUrl: "./colaborador-vinc-cnpj.component.html",
})
export class ColaboradorVincCnpjComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  private readonly destroy$ = new Subject<void>();
  displayedColumns: string[] = [
    "id",
    "nome",
    "ativo",
    "data_expiracao",
    "sistemas_perfis",
    "editar",
  ];

  pessoaJuridicaId!: string | null;
  cnes!: string;
  cnpj!: string;
  cnpjFormatado!: string;
  empresa!: string;
  pessoaJuridicaSerpro!: PessoaJuridicaSerpro;
  nomeFantasia!: string;

  dialogAddPessoaJuridica: any;
  dialogAddColaborador: any;
  dialogSearchEmpresa: any;
  dialogSearchInstituicao: any;
  dialogColabAtrPerfil: any;
  dialogColabAtrPerfilLote: any;
  dialogUpd: any;
  searchValue!: any;
  placeholderText = "Informe o CNPJ ou CNES";
  selectedCompany$: Observable<PessoaJuridica | undefined>;
  allEmployees$ = new Observable<ColaboradorCustom[]>();
  colaboradores: ColaboradorCustom[] = [];
  selectEmployees$?: Observable<ColaboradorCustom[]>;
  selectCompanyPorte$?: Observable<Porte | undefined>;
  selectedOrgOrColab = "pesquisarOrganizacao";
  err: unknown;

  pessoaJuridicaSerpro$: Observable<PessoaJuridicaSerpro | null>;
  filteredEmployees$: Observable<ColaboradorCustom[]>;
  isFiltered$: Observable<boolean>;

  isSelectAll = false;
  isPerfilGestorAnvisaGestorCadastrosResponsavelLegalAdministrador = false;
  isPerfilGestorCadastrosResponsavelLegal = false;
  isPerfilGestorAnvisaGestorAnvisaConsulta = false;
  isPerfilGestorAnvisaGestorAnvisaConsultaGestorCadastrosResponsavelLegal =
    false;
  isPerfilGestorAnvisa = false;
  isPerfilGestorAnvisaConsulta = false;
  isPerfilAdministrador = false;
  isPerfilGestorAnvisaGestorAnvisaConsultaGestorCadastrosResponsavelLegalAdministrador = false;

  filteredEmployees: ColaboradorCustom[] = [];
  allEmployees: ColaboradorCustom[] = [];
  filtered = false;
  private resetSubscription!: Subscription;
  private enableOrgSubscription!: Subscription;
  disableOrg!: boolean;

  constructor(
    private store: Store<AppState>,
    public activatedRoute: ActivatedRoute,
    public toastr: ToastrService,
    public router: Router,
    public dialog: MatDialog,
    private resetService: ResetService,
    private enableOrgService: EnableOrgService
  ) {
    super();
    this.selectedCompany$ = this.store.pipe(select(selectCompany));
    this.allEmployees$ = this.store.pipe(select(selectAllEmployees));
    this.selectEmployees$ = this.store.select(selectAllSelected);
    this.pessoaJuridicaSerpro$ = this.store.select(selectPessoaJuridicaSerpro);
    this.filteredEmployees$ = store.pipe(select(selectAllFilteredEmployees));
    this.isFiltered$ = store.pipe(select(selectIsFiltered));
    this.selectCompanyPorte$ = store.pipe(select(selectCompanyPorte));

    this.store
      .select(selectPerfis)
      .pipe(takeUntil(this.destroy$))
      .subscribe((perfis) => {
        this.resetRoles();

        this.isPerfilGestorAnvisaGestorCadastrosResponsavelLegalAdministrador =
          super.checkPerfilGestorAnvisaGestorCadastrosResponsavelLegalAdministrador(perfis);
        this.isPerfilGestorAnvisaConsulta =
          super.checkPerfilGestorAnvisaConsulta(perfis);
        this.isPerfilGestorCadastrosResponsavelLegal =
          super.checkPerfilGestorCadastrosResponsavelLegal(perfis);
        this.isPerfilGestorAnvisaGestorAnvisaConsulta =
          super.checkPerfilGestorAnvisaGestorAnvisaConsulta(perfis);
        this.isPerfilGestorAnvisaGestorAnvisaConsultaGestorCadastrosResponsavelLegal =
          super.checkPerfilGestorAnvisaGestorAnvisaConsultaGestorCadastrosResponsavelLegal(
            perfis
          );
          this.isPerfilGestorAnvisaGestorAnvisaConsultaGestorCadastrosResponsavelLegalAdministrador =
          super.checkPerfilGestorAnvisaGestorAnvisaConsultaGestorCadastrosResponsavelLegalAdministrador(
            perfis
          );
        this.isPerfilGestorAnvisa = super.checkPerfilGestorAnvisa(perfis);
        this.isPerfilAdministrador = super.checkPerfilAdministrador(perfis);

        if (this.isPerfilGestorAnvisaGestorCadastrosResponsavelLegalAdministrador) {
          this.isPerfilGestorAnvisaConsulta = false;
        } else if (this.isPerfilGestorAnvisaConsulta) {
          this.isPerfilGestorAnvisaGestorCadastrosResponsavelLegalAdministrador = false;
        }
      });

    this.selectedCompany$.subscribe({
      next: (value) => {
        if (value) {
          this.configCnpjCnes(
            value.numeroCNPJ,
            value.razaoSocial,
            value.id,
            value.nomeFantasia,
            value.codigoCnes,
            value.codigoPorte
          );
          this.checarSeTemPermissaoEscrita();
        }
      },
      error: (error) => console.log("Error:" + error),
      complete: () => console.log("Observable completed"),
    });

    this.store
      .select(selectIsUpdatedEmployee)
      .pipe(takeUntil(this.destroy$))
      .subscribe((isUpdated: boolean) => {
        if (isUpdated) {
          this.store.dispatch(
            loadAllEmployees({ idPessoaJuridica: this.pessoaJuridicaId })
          );

          this.store.dispatch(setUpdatedEmployee({ updated: false }));
        }
      });

    this.store
      .select(selectProfile)
      .pipe(takeUntil(this.destroy$))
      .subscribe((profile) => {
        if (
          profile === Constants.PERFIL_GESTOR_CADASTROS ||
          profile === Constants.PERFIL_RESPONSAVEL_LEGAL
        ) {
          this.reset(); // Call the reset function if the profile matches
        } else {
          this.resetAll(); // Call resetAll if the profile doesn't match
        }
      });
  }

  treeControl = new NestedTreeControl<SistemaPerfilNode>(
    (node) => node.children
  );

  hasChild = (_: number, node: SistemaPerfilNode) =>
    !!node.children && node.children.length > 0;

  get dataSourceTableToShow(): ColaboradorCustom[] {
    return this.selectedOrgOrColab === "pesquisarColaborador" && this.filtered
      ? this.filteredEmployees
      : this.allEmployees;
  }

  configSelectedProfile() {
    const selectedProfile =
      Functions.getUsuarioLogadoFromSession()?.selectedProfile || null;
  
    if (
      selectedProfile === null ||
      (
        selectedProfile !== Constants.PERFIL_GESTOR_ANVISA &&
        selectedProfile !== Constants.PERFIL_GESTOR_ANVISA_CONSULTA &&
        selectedProfile !== Constants.PERFIL_ADMINISTRADOR
      )
    ) {
      this.selectedOrgOrColab = "pesquisarColaborador";
      this.changePlaceholder("pesquisarColaborador");
      this.disableOrg = true;
    } else {
      this.selectedOrgOrColab = "pesquisarOrganizacao";
      this.changePlaceholder("pesquisarOrganizacao");
      this.disableOrg = false;
    }
  }  

  ngOnInit() {
    console.log("environment.IS_LOCAL: " + environment.IS_LOCAL);

    this.configSelectedProfile();

    this.enableOrgSubscription =
      this.enableOrgService.enableOrgSubject$.subscribe(() => {
        this.configSelectedProfile();
      });

    this.resetSubscription = this.resetService.reset$.subscribe(() => {
      this.resetAll();
    });

    this.filteredEmployees$
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (filteredEmployees) => (this.filteredEmployees = filteredEmployees)
      );

    this.isFiltered$
      .pipe(takeUntil(this.destroy$))
      .subscribe((filtered) => (this.filtered = filtered));

    this.allEmployees$
      .pipe(takeUntil(this.destroy$))
      .subscribe((employees) => (this.allEmployees = employees));

    this.store
      .select(selectHasWritePermission)
      .pipe(takeUntil(this.destroy$))
      .subscribe((hasPermission) => {
        this.isPerfilGestorAnvisaGestorCadastrosResponsavelLegalAdministrador =
          hasPermission;
        this.isPerfilGestorAnvisaConsulta = !hasPermission;
      });
  }

  public resetRoles(): void {
    this.isPerfilGestorAnvisaGestorCadastrosResponsavelLegalAdministrador = false;
    this.isPerfilGestorAnvisaConsulta = false;
    this.isPerfilGestorCadastrosResponsavelLegal = false;
    this.isPerfilGestorAnvisaGestorAnvisaConsulta = false;
    this.isPerfilGestorAnvisaGestorAnvisaConsultaGestorCadastrosResponsavelLegal =
      false;
    this.isPerfilGestorAnvisa = false;
    this.isPerfilAdministrador = false;
    this.isPerfilGestorAnvisaGestorAnvisaConsultaGestorCadastrosResponsavelLegalAdministrador = false;
  }

  checarSeTemPermissaoEscrita(): void {
    if (!this.isPerfilGestorAnvisa && !this.isPerfilAdministrador) {
      const savedIdPessoaJuridica = sessionStorage.getItem(
        "selectedIdPessoaJuridica"
      );

      const idPessoaRepresentada =
        this.pessoaJuridicaId || savedIdPessoaJuridica;
      if (idPessoaRepresentada) {
        this.store.dispatch(
          checkWritePermission({
            idPessoaRepresentada:
              this.pessoaJuridicaId || savedIdPessoaJuridica,
          })
        );
      }
    } else {
      this.store.dispatch(
        setWritePermission({
          hasPermission: true,
        })
      );
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    if (this.resetSubscription) {
      this.resetSubscription.unsubscribe();
    }
    if (this.enableOrgSubscription) {
      this.enableOrgSubscription.unsubscribe();
    }
  }

  reset() {
    this.searchValue = "";
    this.store.dispatch(setFiltered({ filtered: false }));
    this.isSelectAll = false;
    this.store.dispatch(clearSerproCompany());   
  }

  resetAll() {
    this.searchValue = "";
    this.store.dispatch(setFiltered({ filtered: false }));
    this.isSelectAll = false;
    this.allEmployees = [];
    this.filteredEmployees = [];
    this.configCnpjCnes("", "", "", "", "", null);
    this.store.dispatch(clearCompany());
    this.store.dispatch(clearSerproCompany());
  }

  changePlaceholder(value: string) {
    this.searchValue = "";
    this.store.dispatch(setFiltered({ filtered: false }));
    this.isSelectAll = false;

    switch (value) {
      case "pesquisarOrganizacao":
        this.placeholderText = "Informe o CNPJ ou CNES";
        break;
      case "pesquisarColaborador":
        this.placeholderText = "Informe o Nome";
        break;
      default:
        this.placeholderText = "Informe o CNPJ ou CNES";
    }
  }

  continueSearch(inputSearchValue: string): void {
    if (Functions.isEmpty(inputSearchValue)) {
      ToastrFunctions.showWarning(this.toastr, "Informe um valor.");
      return;
    }

    if (this.selectedOrgOrColab === "pesquisarOrganizacao") {
      if (Functions.isNumber(inputSearchValue)) {
        if (inputSearchValue.length === 7) {
          if (
            !this
              .isPerfilGestorAnvisaGestorAnvisaConsultaGestorCadastrosResponsavelLegalAdministrador
          ) {
            ToastrFunctions.showWarning(
              this.toastr,
              "É necessário ter Perfil Gestor Anvisa Consulta/Gestor Anvisa/Gestor de Cadastros/Responsável Legal para realizar esta pesquisa."
            );
            return;
          }

          this.openDialogSearchInstituicao("0ms", "0ms", inputSearchValue);
        } else {
          ToastrFunctions.showError(
            this.toastr,
            "Número de organização inválido. CNES deve ter 7 dígitos e CNPJ deve ter 14 dígitos."
          );
        }
      } else {
        const cnpjValidationMessage = Functions.isCnpj(inputSearchValue);
        if (cnpjValidationMessage === "CNPJ válido.") {
          if (
            !this
              .isPerfilGestorAnvisaGestorAnvisaConsultaGestorCadastrosResponsavelLegalAdministrador
          ) {
            ToastrFunctions.showWarning(
              this.toastr,
              "É necessário ter Perfil Gestor Anvisa Consulta/Gestor Anvisa/Gestor de Cadastros/Responsável Legal para realizar esta pesquisa."
            );
            return;
          }

          this.openDialogSearchEmpresa("0ms", "0ms", inputSearchValue);
        } else {
          ToastrFunctions.showError(this.toastr, cnpjValidationMessage);
        }
      }
    } else if (this.selectedOrgOrColab === "pesquisarColaborador") {
      if (Functions.isValidName(inputSearchValue) && inputSearchValue.trim().length >= 3) {
        this.store.dispatch(
          chooseEmployees({
            employees: this.allEmployees,
            noPessoaFisica: inputSearchValue,
          })
        );
      } else {
        ToastrFunctions.showWarning(
          this.toastr,
          "Informe um nome válido com pelo menos 3 caracteres para pesquisar colaboradores."
        );
      }
    }
    
  }

  onInputChange() {
    if (this.selectedOrgOrColab === "pesquisarOrganizacao") {
      if (this.searchValue.length === 7) {
        // cnes
        this.searchValue = Functions.applyMask(this.searchValue, "XXXXXXX");
      } else if (this.searchValue.length === 14) {
        // cnpj
        this.searchValue = Functions.applyMask(
          this.searchValue,
          "XX.XXX.XXX/XXXX-XX"
        );
      }
    }
  }  

  configCnpjCnes(
    cnpj: string,
    empresa: string,
    pessoaJuridicaId: string | null,
    nomeFantasia: string,
    cnes: string,
    codigoPorte: number | null
  ) {
    this.empresa = empresa;
    this.pessoaJuridicaId = pessoaJuridicaId;
    this.nomeFantasia = nomeFantasia;
    this.cnpj = cnpj;
    this.cnes = cnes;
    if (cnpj) {
      this.cnpjFormatado = Functions.formatCnpj(this.cnpj);
      this.store.dispatch(consultaCNPJ({ cnpj }));
    } else {
      this.cnpjFormatado = "";
    }

    if (codigoPorte) {
      this.store.dispatch(consultaPorte({ codigoPorte }));
    } else {
      this.store.dispatch(clearPorte());
    }
  }

  createTree(sistemaPerfisNode: SistemaPerfilNode[]) {
    const dataSource = new MatTreeNestedDataSource<SistemaPerfilNode>();
    dataSource.data = sistemaPerfisNode;
    return dataSource;
  }

  openDialogAddColaborador(
    enterAnimationDuration: string,
    exitAnimationDuration: string
  ): void {
    this.dialogAddColaborador = this.dialog.open(
      DialogAddColaboradorComponent,
      {
        width: "500px",
        enterAnimationDuration,
        exitAnimationDuration,
        data: { pessoaJuridicaId: this.pessoaJuridicaId },
      }
    );
  }

  openDialogSearchEmpresa(
    enterAnimationDuration: string,
    exitAnimationDuration: string,
    cnpj: string
  ): void {
    this.dialogSearchEmpresa = this.dialog.open(DialogSearchEmpresaComponent, {
      width: "500px",
      enterAnimationDuration,
      exitAnimationDuration,
      data: { CNPJ: cnpj },
    });
    this.dialogSearchEmpresa.afterClosed().subscribe((result: any) => {
      if (result !== "") {
        const pessoaJuridica: PessoaJuridica = result;
        this.store.dispatch(
          loadCompany({ idPessoaJuridica: pessoaJuridica.id })
        );
        this.store.dispatch(
          loadAllEmployees({ idPessoaJuridica: pessoaJuridica.id })
        );
      }
    });
  }

  openDialogSearchInstituicao(
    enterAnimationDuration: string,
    exitAnimationDuration: string,
    cnes: string
  ): void {
    this.dialogSearchInstituicao = this.dialog.open(
      DialogSearchInstituicaoComponent,
      {
        width: "700px",
        enterAnimationDuration,
        exitAnimationDuration,
        data: {
          cnes: cnes,
        },
      }
    );
    this.dialogSearchInstituicao.afterClosed().subscribe((result: any) => {
      if (result && result !== "") {
        const pessoaJuridica: PessoaJuridica = result;
        if (!Functions.isEmpty(pessoaJuridica.id)) {
          this.store.dispatch(
            loadCompany({ idPessoaJuridica: pessoaJuridica.id })
          );
          this.store.dispatch(
            loadAllEmployees({ idPessoaJuridica: pessoaJuridica.id })
          );
        }
      }
    });
  }

  checkAndOpenDialogColabAtrPerfil(
    enterAnimationDuration: string,
    exitAnimationDuration: string,
    idUsuarioRepresentante: string,
    noPessoaFisica: string,
    isPerfisReadOnly: boolean
  ): void {
    this.openDialogColabAtrPerfil(
      enterAnimationDuration,
      exitAnimationDuration,
      idUsuarioRepresentante,
      noPessoaFisica,
      isPerfisReadOnly
    );
  }

  openDialogColabAtrPerfil(
    enterAnimationDuration: string,
    exitAnimationDuration: string,
    idUsuarioRepresentante: string,
    noPessoaFisica: string,
    isPerfisReadOnly: boolean
  ) {
    this.dialogColabAtrPerfil = this.dialog.open(
      DialogColabAtrPerfilComponent,
      {
        width: "1000px",
        enterAnimationDuration,
        exitAnimationDuration,
        data: {
          idUsuarioRepresentante: idUsuarioRepresentante,
          noPessoaFisica: noPessoaFisica,
          isPerfisReadOnly: isPerfisReadOnly,
          cnpj: this.cnpjFormatado ? this.cnpjFormatado : "",
          empresa: this.empresa,
          isEmpresa: Functions.isEmpty(this.cnes),
          cnes: this.cnes ? this.cnes : "",
          pessoaJuridicaId: this.pessoaJuridicaId,
          isPerfilGestorAnvisa: this.isPerfilGestorAnvisa || this.isPerfilAdministrador,
        },
      }
    );
  }

  openDialogColabAtrPerfilLote(
    enterAnimationDuration: string,
    exitAnimationDuration: string
  ) {
    this.dialogColabAtrPerfilLote = this.dialog.open(
      DialogColabAtrPerfilLoteComponent,
      {
        width: "1000px",
        enterAnimationDuration,
        exitAnimationDuration,
        data: {
          pessoaJuridicaId: this.pessoaJuridicaId,
          isPerfilGestorAnvisa: this.isPerfilGestorAnvisa || this.isPerfilAdministrador,
        },
      }
    );
  }

  toggleAllChb() {
    this.isSelectAll = !this.isSelectAll;
    this.store.dispatch(toggleAllEmployees({ isSelected: this.isSelectAll }));
  }

  toggleChb(idUsuarioRepresentante: string, isSelected: boolean) {
    this.store.dispatch(
      toggleEmployee({
        idUsuarioRepresentante: idUsuarioRepresentante,
        isSelected: isSelected,
      })
    );
  }

  getSituacaoCadastralDesc(situacaoCadastral: SituacaoCadastralSerpro): string {
    return `Código Situação Cadastral: ${situacaoCadastral.getSituacaoCadastralDesc()}`;
  }
}
