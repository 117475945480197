export class DynamicFlatNode {
    constructor(
        public id: string,
        public item: string,
        public level: number = 0,
        public expandable: boolean = false,
        public isLoading: boolean = false
    ) {}
}

