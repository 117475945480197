export class Constants {
    
    /* Perfis */
    static readonly PERFIL_GESTOR_ANVISA = 'Gestor Anvisa';
    static readonly PERFIL_GESTOR_ANVISA_CONSULTA = 'Gestor Anvisa Consulta';
    static readonly PERFIL_GESTOR_CADASTROS = 'Gestor de Cadastros';
    static readonly PERFIL_RESPONSAVEL_LEGAL = 'Responsável Legal';
    static readonly PERFIL_ADMINISTRADOR = 'Administrador';

    static readonly CPF_DESCONHECIDO = "00000000000"

    static readonly CNPJ_ANVISA = "03112388000111";

    static readonly HOME_PAGE = "/home";
      
}