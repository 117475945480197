
export class PerfisInternoColaboradorRequest {
    idUsuarioRepresentante: string;
    isStBloqueado: boolean;
    idsPerfisInterno: string[];
          
    constructor(idUsuarioRepresentante: string, isStBloqueado: boolean, idsPerfisInterno: string[]) {
      this.idUsuarioRepresentante = idUsuarioRepresentante;
      this.isStBloqueado = isStBloqueado;
      this.idsPerfisInterno = idsPerfisInterno;
    }
  
  }