import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EnableOrgService {
  private enableOrgSubject = new Subject<void>();

  enableOrgSubject$ = this.enableOrgSubject.asObservable();

  triggerEnableOrg() {
    this.enableOrgSubject.next();
  }
}
