import { Component } from "@angular/core";
import { Perfil } from "../model/perfil.model";
import { Constants } from "./constants";

@Component({
  selector: "app-base",
  template: ` <p>base works!</p> `,
  styles: [],
})
export class BaseComponent {
  perfis!: Perfil[];

  checkPerfis(perfilToBeChecked: string, perfis: Array<Perfil>): boolean {
    return perfis?.some(item => item.noPerfil === perfilToBeChecked) ?? false;
  }

  checkTodosPerfis(perfis: Array<Perfil>) {
    if (
      !this.checkPerfis(Constants.PERFIL_GESTOR_CADASTROS, perfis) &&
      !this.checkPerfis(Constants.PERFIL_GESTOR_ANVISA, perfis) &&
      !this.checkPerfis(Constants.PERFIL_GESTOR_ANVISA_CONSULTA, perfis) &&
      !this.checkPerfis(Constants.PERFIL_RESPONSAVEL_LEGAL, perfis) &&
      !this.checkPerfis(Constants.PERFIL_ADMINISTRADOR, perfis)
    ) {
      console.log(
        "É necessário ter Perfil Gestor de Cadastros/Gestor Anvisa/Gestor Anvisa Consulta/Responsável Legal/Administrador para editar esta página."
      );
      return false;
    } else {
      return true;
    }
  }

  //edição
  checkPerfilGestorAnvisaGestorCadastrosResponsavelLegalAdministrador(
    perfis: Array<Perfil>
  ) {
    if (
      this.checkPerfis(Constants.PERFIL_GESTOR_CADASTROS, perfis) ||
      this.checkPerfis(Constants.PERFIL_GESTOR_ANVISA, perfis) ||
      this.checkPerfis(Constants.PERFIL_RESPONSAVEL_LEGAL, perfis) ||
      this.checkPerfis(Constants.PERFIL_ADMINISTRADOR, perfis)
    ) {
      return true;
    } else {
      console.log(
        "É necessário ter Perfil Gestor de Cadastros / Gestor Anvisa / Responsável Legal / Administrador para editar esta página."
      );
      return false;
    }
  }

  checkPerfilGestorCadastrosResponsavelLegal(perfis: Array<Perfil>) {
    if (
      this.checkPerfis(Constants.PERFIL_GESTOR_CADASTROS, perfis) ||
      this.checkPerfis(Constants.PERFIL_RESPONSAVEL_LEGAL, perfis)
    ) {
      return true;
    } else {
      console.log(
        "É necessário ter Perfil Gestor de Cadastros ou Responsável Legal para editar esta página."
      );
      return false;
    }
  }

  //visualização
  checkPerfilGestorAnvisaConsulta(perfis: Array<Perfil>) {
    if (
      this.checkPerfis(Constants.PERFIL_GESTOR_ANVISA_CONSULTA, perfis)
    ) {
      return true;
    } else {
      console.log(
        "É necessário ter Perfil Gestor Anvisa Consulta para editar esta página."
      );
      return false;
    }
  }

  //procurar empresas
  checkPerfilGestorAnvisaGestorAnvisaConsulta(perfis: Array<Perfil>) {
    if (
      this.checkPerfis(Constants.PERFIL_GESTOR_ANVISA_CONSULTA, perfis) ||
      this.checkPerfis(Constants.PERFIL_GESTOR_ANVISA, perfis)
    ) {
      return true;
    } else {
      console.log(
        "É necessário ter Perfil Gestor Anvisa Consulta ou Perfil Gestor Anvisa para editar esta página."
      );
      return false;
    }
  }

  //administrador
  checkPerfilAdministrador(perfis: Array<Perfil>) {
    if (!this.checkPerfis(Constants.PERFIL_ADMINISTRADOR, perfis)) {
      console.log(
        "É necessário ter Perfil Administrador para editar esta página."
      );
      return false;
    } else {
      return true;
    }
  }

  //procurar instituições e log (auditoria)
  checkPerfilGestorAnvisaGestorAnvisaConsultaGestorCadastrosResponsavelLegal(
    perfis: Array<Perfil>
  ) {
    if (
      this.checkPerfis(Constants.PERFIL_GESTOR_ANVISA_CONSULTA, perfis) ||
      this.checkPerfis(Constants.PERFIL_GESTOR_ANVISA, perfis) ||
      this.checkPerfis(Constants.PERFIL_GESTOR_CADASTROS, perfis) ||
      this.checkPerfis(Constants.PERFIL_RESPONSAVEL_LEGAL, perfis)
    ) {
      return true;
    } else {
      console.log(
        "É necessário ter Perfil Gestor Anvisa Consulta/Gestor Anvisa/Gestor de Cadastros/Responsável Legal para editar esta página."
      );
      return false;
    }
  }

    //procurar instituições e log (auditoria)
    checkPerfilGestorAnvisaGestorAnvisaConsultaGestorCadastrosResponsavelLegalAdministrador(
      perfis: Array<Perfil>
    ) {
      if (
        this.checkPerfis(Constants.PERFIL_GESTOR_ANVISA_CONSULTA, perfis) ||
        this.checkPerfis(Constants.PERFIL_GESTOR_ANVISA, perfis) ||
        this.checkPerfis(Constants.PERFIL_GESTOR_CADASTROS, perfis) ||
        this.checkPerfis(Constants.PERFIL_RESPONSAVEL_LEGAL, perfis) ||
        this.checkPerfis(Constants.PERFIL_ADMINISTRADOR, perfis)
      ) {
        return true;
      } else {
        console.log(
          "É necessário ter Perfil Gestor Anvisa Consulta/Gestor Anvisa/Gestor de Cadastros/Responsável Legal/Administrador para editar esta página."
        );
        return false;
      }
    }

  //edição
  checkPerfilGestorAnvisa(
    perfis: Array<Perfil>
  ) {
    if (
      !this.checkPerfis(Constants.PERFIL_GESTOR_ANVISA, perfis)
    ) {
      console.log(
        "Não é Perfil Gestor Anvisa."
      );
      return false;
    } else {
      return true;
    }
  }

  checkOnlyPerfilGestorAnvisaConsulta(
    perfis: Array<Perfil>
  ) {    
    if (
      this.checkPerfis(Constants.PERFIL_GESTOR_ANVISA_CONSULTA, perfis)
        && !this.checkPerfis(Constants.PERFIL_GESTOR_ANVISA, perfis)
        && !this.checkPerfis(Constants.PERFIL_ADMINISTRADOR, perfis)
    ) {
      console.log(
        "É Perfil Gestor Anvisa Consulta."
      );
      return true;
    } else {
      return false;
    }
  }
}
