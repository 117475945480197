
export class SistemaPerfisColaboradorRequest {
    idUsuarioRepresentante: string;
    idSistema: string;
    idsPerfis: string[];
    cnpjsOrigem: string[];
    cpf: string;
      
    constructor(idUsuarioRepresentante: string, idSistema: string, idsPerfis: string[], cnpjsOrigem: string[], cpf: string) {
      this.idUsuarioRepresentante = idUsuarioRepresentante;
      this.idSistema = idSistema;
      this.idsPerfis = idsPerfis;
      this.cnpjsOrigem = cnpjsOrigem;
      this.cpf = cpf;
    }
  
  }