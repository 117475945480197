import { createReducer, on } from '@ngrx/store';
import * as LogActions from '../actions/log.action';
import { LogState } from '../interfaces/log-state.interface';


export const initialState: LogState = {
  logs: null,
  isLoaded: {chooseLogs: true},
  error: undefined,
};

export const logReducer = createReducer(
  initialState,
  on(LogActions.chooseLogs, (state,) => ({
    ...state,
    isLoaded: { ...state.isLoaded, chooseLogs: false },
    error: null
  })),

  on(LogActions.chooseLogsSuccess, (state, { logs }) => ({
    ...state,
    logs,
    isLoaded: { ...state.isLoaded, chooseLogs: true },
    error: null
  })),
  on(LogActions.chooseLogsFailure, (state, { error }) => ({
    ...state,
    error,
    isLoaded: { ...state.isLoaded, chooseLogs: true },
  }))
);

