// src/environments/environment.feature.ts
export const environment = {
  production: false,
  API: 'https://cadastro-anvisa-backend.hmg.apps.anvisa.gov.br',
  AUTH_SERVER: 'https://acesso.dev.apps.anvisa.gov.br/auth/',
  SERPRO_API: 'https://cnpj-cpf-backend.hmg.apps.anvisa.gov.br',
  REALM: 'anvisa',
  CLIENT_ID: 'front-anvisa',
  IS_LOCAL: false,
  websocketUrl: 'wss://cadastro-anv-notif.hmg.apps.anvisa.gov.br/ws',
  GOVBR_LOGOUT_URL: 'https://sso.staging.acesso.gov.br/logout?post_logout_redirect_uri=https://cadastro-anvisa-frontend.tst.apps.anvisa.gov.br'
};

