/* eslint-disable @typescript-eslint/no-unused-vars */
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { KeycloakService } from "keycloak-angular";
import { environment } from "src/environments/environment";
import { map, Observable } from "rxjs";
import { PerfisInterno } from "../model/perfisinterno";
import { PerfilInterno } from "../model/perfilinterno";

@Injectable({
  providedIn: "root",
})
export class PerfilInternoService {
  private readonly API = `${environment.API}/api/v1/perfisinterno`;

  private token: unknown;
  perfisInterno$: any;
  selectedUser$: any;

  constructor(
    private httpClient: HttpClient,
    private readonly keycloackService: KeycloakService
  ) {}

  getToken() {
    return this.keycloackService.getToken();
  }

  findPerfilInternoByFilter(nuCpf: string = '', noPessoaFisica: string = '', dsEmail: string = '', page: number = 0, size: number = 20): Observable<PerfisInterno> {
    const api_key = this.token;
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${api_key}`,
    });
    
    let params = new HttpParams()
      .set('nuCpf', nuCpf)
      .set('noPessoaFisica', noPessoaFisica)
      .set('dsEmail', dsEmail)
      .set('page', page.toString())
      .set('size', size.toString());

    return this.httpClient.get<PerfisInterno>(`${this.API}/find-perfil-interno-by-filter`, { headers, params });
  }

  findAllPerfilInterno(): Observable<PerfilInterno[]> {
    const api_key = this.token;
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${api_key}`,
    });    
    return this.httpClient.get<PerfilInterno[]>(`${this.API}/find-all-perfil-interno`, { headers });
  }

  atualizaPerfisUsuarioInterno(data: any): Observable<boolean> {
    this.token = this.getToken();
    const api_key = this.token;
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${api_key}`,
    });
    const API_URL = `${this.API}/atualiza-perfis-interno-usuario-interno`;
    return this.httpClient
      .put<boolean>(API_URL, data, {
        headers: headers,
        responseType: "text" as "json",
      })
      .pipe(map((response: any) => response));
  }
  
}
