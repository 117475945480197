import { KeycloakService } from "keycloak-angular";
import { environment } from 'src/environments/environment';

export function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        url: `${environment.AUTH_SERVER}`,
        realm: `${environment.REALM}`,
        clientId: `${environment.CLIENT_ID}`
      },
      initOptions: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri:
        window.location.origin + '/assets/silent-check-sso.html',
      },
    loadUserProfileAtStartUp: true

  });
}
