import { SituacaoCadastralSerpro } from "./situacaocadastralserpro";

interface NaturezaJuridica {
  codigo: string;
  descricao: string;
}

interface CnaeInfo {
  codigo: string;
  descricao: string;
}

interface Endereco {
  tipoLogradouro: string;
  logradouro: string;
  numero: string;
  complemento: string;
  cep: string;
  bairro: string;
  municipio: Municipio;
  uf: string;
  pais: Pais;
}

interface Municipio {
  codigo: string;
  descricao: string;
}

interface Pais {
  codigo: string;
  descricao: string;
}

interface Telefone {
  ddd: string;
  numero: string;
}

interface InformacoesAdicionais {
  optanteSimples: string;
  optanteMei: string;
  listaPeriodosSimples: PeriodoSimples[];
}

interface PeriodoSimples {
  dataInicio: string;
  dataFim: string;
}

interface Socio {
  tipoSocio: string;
  cpf: string;
  nome: string;
  qualificacao: string;
  dataInclusao: string;
  pais: Pais;
  representanteLegal: RepresentanteLegal;
}

interface RepresentanteLegal {
  cpf: string;
  nome: string;
  qualificacao: string;
}

export class PessoaJuridicaSerpro {
  ni: string;
  nomeEmpresarial: string;
  nomeFantasia: string;
  situacaoCadastral: SituacaoCadastralSerpro;
  cnaePrincipal: CnaeInfo;
  cnaeSecundarias: CnaeInfo[];
  tipoEstabelecimento: string;
  naturezaJuridica: NaturezaJuridica;
  dataAbertura: string;
  endereco: Endereco;
  municipioJurisdicao: Municipio;
  telefones: Telefone[];
  correioEletronico: string;
  capitalSocial: string;
  porte: string;
  situacaoEspecial: string;
  dataSituacaoEspecial: string;
  informacoesAdicionais: InformacoesAdicionais;
  socios: Socio[];

  constructor(data?: Partial<PessoaJuridicaSerpro>) {
      this.ni = data?.ni ?? '';
      this.nomeEmpresarial = data?.nomeEmpresarial ?? '';
      this.nomeFantasia = data?.nomeFantasia ?? '';
      this.situacaoCadastral = data?.situacaoCadastral ? new SituacaoCadastralSerpro(data.situacaoCadastral) : new SituacaoCadastralSerpro();
      this.cnaePrincipal = data?.cnaePrincipal ?? { codigo: '', descricao: '' };
      this.cnaeSecundarias = data?.cnaeSecundarias ?? [];
      this.tipoEstabelecimento = data?.tipoEstabelecimento ?? '';
      this.naturezaJuridica = data?.naturezaJuridica ?? { codigo: '', descricao: '' };
      this.dataAbertura = data?.dataAbertura ?? '';
      this.endereco = data?.endereco ?? { tipoLogradouro: '', logradouro: '', numero: '', complemento: '', cep: '', bairro: '', municipio: { codigo: '', descricao: '' }, uf: '', pais: { codigo: '', descricao: '' } };
      this.municipioJurisdicao = data?.municipioJurisdicao ?? { codigo: '', descricao: '' };
      this.telefones = data?.telefones ?? [];
      this.correioEletronico = data?.correioEletronico ?? '';
      this.capitalSocial = data?.capitalSocial ?? '';
      this.porte = data?.porte ?? '';
      this.situacaoEspecial = data?.situacaoEspecial ?? '';
      this.dataSituacaoEspecial = data?.dataSituacaoEspecial ?? '';
      this.informacoesAdicionais = data?.informacoesAdicionais ?? { optanteSimples: '', optanteMei: '', listaPeriodosSimples: [] };
      this.socios = data?.socios ?? [];
  }
}