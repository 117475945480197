import { Component, Inject, OnInit, OnDestroy } from "@angular/core";
import { FormBuilder, FormGroup, FormArray, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { KeycloakClient } from "src/app/model/keycloakclient.model";

@Component({
  selector: "app-add-integration-dialog",
  templateUrl: "./add-integration-dialog.component.html",
  styleUrls: ["./add-integration-dialog.component.scss"],
})
export class AddIntegrationDialogComponent implements OnInit {
  keycloakForm!: FormGroup;
  isEditMode = false;
  initialFormValue: any;

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<AddIntegrationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: KeycloakClient
  ) {
    this.initializeForm();
  }

  ngOnInit(): void {
    if (this.data) {
      this.loadData(this.data);
    }
  }

  private initializeForm(): void {
    this.keycloakForm = this.formBuilder.group({
      clientId: ["", Validators.required],
      name: ["", Validators.required],
      description: [""],
      rootUrl: ["", Validators.required],
      baseUrl: ["", Validators.required],
      adminUrl: [""],
      redirectUris: this.formBuilder.array([this.createUriControl()]),
      webOrigins: this.formBuilder.array([this.createOriginControl()]),
      sistemaId: ["", Validators.required],
    });
  }

  private loadData(data: KeycloakClient): void {
    this.isEditMode = !!data.clientId;
    this.keycloakForm.patchValue(data);

    if (this.isEditMode)
      this.keycloakForm.get('clientId')?.disable();

    this.keycloakForm.setControl('redirectUris', this.formBuilder.array(data.redirectUris?.map(uri => this.formBuilder.group({ uri: [uri, Validators.required] })) || [this.createUriControl()]));
    this.keycloakForm.setControl('webOrigins', this.formBuilder.array(data.webOrigins?.map(origin => this.formBuilder.group({ origin: [origin, Validators.required] })) || [this.createOriginControl()]));

    this.initialFormValue = this.keycloakForm.value;
  }

  private createUriControl(): FormGroup {
    return this.formBuilder.group({
      uri: ['', Validators.required]
    });
  }

  private createOriginControl(): FormGroup {
    return this.formBuilder.group({
      origin: ['', Validators.required]
    });
  }

  get redirectUris(): FormArray {
    return this.keycloakForm.get('redirectUris') as FormArray;
  }

  get webOrigins(): FormArray {
    return this.keycloakForm.get('webOrigins') as FormArray;
  }

  addRedirectUri(): void {
    this.redirectUris.push(this.createUriControl());
  }

  addWebOrigin(): void {
    this.webOrigins.push(this.createOriginControl());
  }

  deleteRedirectUri(index: number): void {
    this.redirectUris.removeAt(index);
  }

  deleteWebOrigin(index: number): void {
    this.webOrigins.removeAt(index);
  }

  onSubmit(): void {
    if (this.keycloakForm.valid) {
      const formData = this.prepareSubmitData();
      this.dialogRef.close(formData);
    }
  }

  private prepareSubmitData(): any {
    this.keycloakForm.enable(); // Temporarily enable all fields
    const formData = { ...this.keycloakForm.value };
    formData.redirectUris = formData.redirectUris.map((uriControl: { uri: string }) => uriControl.uri);
    formData.webOrigins = formData.webOrigins.map((originControl: { origin: string }) => originControl.origin);
    this.keycloakForm.disable(); // Disable fields again
    return formData;
  }

  public hasFormChanged(): boolean {
    return JSON.stringify(this.keycloakForm.value) !== JSON.stringify(this.initialFormValue);
  }

}
