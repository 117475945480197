<!-- notification.component.html -->
<div>
  <button mat-icon-button [matMenuTriggerFor]="menu" class="notification-button"
          matBadge="{{ notifications.length }}" matBadgeHidden="{{ notifications.length === 0 }}"
          matBadgeColor="warn" matBadgePosition="above after">
    <mat-icon>notifications</mat-icon>
  </button>

  <mat-menu #menu="matMenu">
    <div *ngIf="notifications.length > 0; else noNotifications">
      <button mat-menu-item *ngFor="let notification of notifications; let i = index">
        {{ notification }}
        <button mat-icon-button color="warn" (click)="deleteNotification(i, $event)" matTooltip="Deletar notificação">
          <mat-icon>close</mat-icon>
        </button>
      </button>
    </div>
    <ng-template #noNotifications>
      <button mat-menu-item disabled>Sem notificações ainda.</button>
    </ng-template>
  </mat-menu>
</div>