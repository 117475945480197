import { createAction, props } from '@ngrx/store';
import { Logs } from '../model/logs';

export const chooseLogs = createAction(
  '[Log] Choose Logs',
  props<{ 
    noPessoaFisica: string, 
    joinPessoaJuridica: boolean,
    idPessoaJuridica: string | null,
    intervalo: number, 
    page: number, 
    size: number 
  }>()
);

export const chooseLogsSuccess = createAction(
  '[Log] Choose Logs Success',
  props<{ logs: Logs }>()
);

export const chooseLogsFailure = createAction(
  '[Log] Choose Logs Failure',
  props<{ error: any }>()
);






