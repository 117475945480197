<h2 mat-dialog-title>Detalhes da Integração do Cliente</h2>
<mat-dialog-content class="view-dialog-content">
  <section aria-labelledby="client-details">
    <h3 id="client-details">Detalhes do Cliente</h3>
    <p><strong>ID do Cliente:</strong> {{ data.clientId }}</p>
    <p><strong>Senha do Cliente:</strong></p>
    <div *ngIf="!showSecret">
      <span>********</span>
    </div>
    <div *ngIf="showSecret">
      <span>{{ data.secret }}</span>
    </div>
    <button mat-icon-button (click)="toggleSecret()" aria-label="Toggle secret visibility">
      <mat-icon aria-hidden="true">{{ showSecret ? 'remove_red_eye' : 'remove_red_eye_off' }}</mat-icon>
    </button>
  </section>

  <section aria-labelledby="additional-details">
    <h3 id="additional-details">Informações Adicionais</h3>
    <p><strong>Nome:</strong> {{ data.name }}</p>
    <p><strong>Descrição:</strong> {{ data.description }}</p>
    <p><strong>URL Raiz:</strong> {{ data.rootUrl }}</p>
    <p><strong>URL do Admin:</strong> {{ data.adminUrl }}</p>
    <p><strong>URL Base:</strong> {{ data.baseUrl }}</p>
    <p><strong>Habilitado:</strong> {{ data.enabled ? 'Sim' : 'Não' }}</p>
  </section>

  <section aria-labelledby="redirect-uris">
    <h3 id="redirect-uris">URIs de Redirecionamento</h3>
    <ul>
      <li *ngFor="let uri of data.redirectUris">{{ uri }}</li>
    </ul>
  </section>

  <section aria-labelledby="web-origins">
    <h3 id="web-origins">Origens Web</h3>
    <ul>
      <li *ngFor="let origin of data.webOrigins">{{ origin }}</li>
    </ul>
  </section>

  <section aria-labelledby="other-details">
    <h3 id="other-details">Outros Detalhes</h3>
    <p><strong>Apenas Portador:</strong> {{ data.bearerOnly ? 'Sim' : 'Não' }}</p>
    <p><strong>Consentimento Requerido:</strong> {{ data.consentRequired ? 'Sim' : 'Não' }}</p>
    <p><strong>Fluxo Padrão Habilitado:</strong> {{ data.standardFlowEnabled ? 'Sim' : 'Não' }}</p>
    <p><strong>Fluxo Implícito Habilitado:</strong> {{ data.implicitFlowEnabled ? 'Sim' : 'Não' }}</p>
    <p><strong>Concessões Diretas de Acesso Habilitadas:</strong> {{ data.directAccessGrantsEnabled ? 'Sim' : 'Não' }}</p>
    <p><strong>Contas de Serviço Habilitadas:</strong> {{ data.serviceAccountsEnabled ? 'Sim' : 'Não' }}</p>
    <p><strong>Cliente Público:</strong> {{ data.publicClient ? 'Sim' : 'Não' }}</p>
    <p><strong>Protocolo:</strong> {{ data.protocol }}</p>
  </section>

  <section aria-labelledby="attributes">
    <h3 id="attributes">Atributos</h3>
    <ul>
      <li *ngFor="let key of objectKeys(data.attributes)">
        {{ key }}: {{ data.attributes[key] }}
      </li>
    </ul>
  </section>

  <section aria-labelledby="default-scopes">
    <h3 id="default-scopes">Escopos Padrão do Cliente</h3>
    <ul>
      <li *ngFor="let scope of data.defaultClientScopes">{{ scope }}</li>
    </ul>
  </section>

  <section aria-labelledby="optional-scopes">
    <h3 id="optional-scopes">Escopos Opcionais do Cliente</h3>
    <ul>
      <li *ngFor="let scope of data.optionalClientScopes">{{ scope }}</li>
    </ul>
  </section>

  <p><strong>Origem:</strong> {{ data.origin }}</p>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>Fechar</button>
</mat-dialog-actions>
