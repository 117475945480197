// src/app/store/selectors/auth.selectors.ts
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthState } from '../interfaces/auth-state.interface';
import { createIsLoadedSelector } from './genericselector.interface';

export const selectAuthState = createFeatureSelector<AuthState>('auth');

export const selectPerfis = createSelector(
  selectAuthState,
  (state: AuthState) => state.perfis
);

export const selectPerfisByPessoaJuridicaId = createSelector(
  selectAuthState,
  (state: AuthState) => state.perfisByPessoaJuridicaId
);

export const selectHasWritePermission = createSelector(
  selectAuthState,
  (state: AuthState) => state.hasWritePermission
);

export const selectAuthError = createSelector(
  selectAuthState,
  (state: AuthState) => state.error
);

export const selectGovBrToken = createSelector(
  selectAuthState,
  (state: AuthState) => state.token
);

export const getAuthIsLoaded = createIsLoadedSelector(selectAuthState);

// Selectors for the boolean profile properties
export const selectIsGestorAnvisa = createSelector(
  selectAuthState,
  (state: AuthState) => state.isGestorAnvisa
);

export const selectIsGestorAnvisaConsulta = createSelector(
  selectAuthState,
  (state: AuthState) => state.isGestorAnvisaConsulta
);

export const selectIsGestorCadastros = createSelector(
  selectAuthState,
  (state: AuthState) => state.isGestorCadastros
);

export const selectIsResponsavelLegal = createSelector(
  selectAuthState,
  (state: AuthState) => state.isResponsavelLegal
);

export const selectIsAdministrador = createSelector(
  selectAuthState,
  (state: AuthState) => state.isAdministrador
);

export const selectProfile = createSelector(
  selectAuthState,
  (state: AuthState) => state.selectedProfile
);
