import { createSelector, createFeatureSelector } from "@ngrx/store";
import { createIsLoadedSelector } from "./genericselector.interface";
import { EmployeeState } from "../interfaces/employee-state.interface";

export const selectEmployeeState =
  createFeatureSelector<EmployeeState>("employees");

export const selectAllEmployees = createSelector(
  selectEmployeeState,
  (state: EmployeeState) => state.employees
);

export const selectAllFilteredEmployees = createSelector(
  selectEmployeeState,
  (state: EmployeeState) => state.filteredEmployees
);

export const selectEmployee = createSelector(
  selectEmployeeState,
  (state: EmployeeState) => state.selectedEmployee
);

export const selectInternalEmployee = createSelector(
  selectEmployeeState,
  (state: EmployeeState) => state.selectedInternalEmployee
);

export const selectIsUpdatedEmployee = createSelector(
  selectEmployeeState,
  (state: EmployeeState) => state.updated
);

export const selectAllSelected = createSelector(
  selectEmployeeState,
  (state: EmployeeState) => {
    if (state.filtered) {
      return state.filteredEmployees.filter(employee => employee.selected);
    } else {
      return state.employees.filter(employee => employee.selected);
    }
  }
);

export const selectEmployees = createSelector(
  selectEmployeeState,
  (state: EmployeeState) => state.selectedEmployees
);

export const selectIsFiltered = createSelector(
  selectEmployeeState,
  (state: EmployeeState) => state.filtered
);

export const selectColaboradorSerpro = createSelector(
  selectEmployeeState,
  (state: EmployeeState) => state.colaboradorSerpro
);

export const getEmployeeIsLoaded = createIsLoadedSelector(selectEmployeeState);
