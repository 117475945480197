<div class="dark-overlay" *ngIf="showSpinner"></div>
<div class="spinner">
  <mat-spinner *ngIf="showSpinner"></mat-spinner>
</div>
<mat-card>
  <mat-card-header>
    <mat-card-title>Gerenciar Integrações</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-form-field appearance="fill">
      <mat-label>Selecione o Sistema</mat-label>
      <mat-select
        [(ngModel)]="selectedSistema"
        (selectionChange)="onSistemaSelected($event.value)"
      >
        <mat-option
          *ngFor="let sistema of sistemas; trackBy: trackBySistema"
          [value]="sistema"
        >
          {{ sistema.noSistema }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </mat-card-content>
  <mat-card-actions>
    <button
      mat-raised-button
      color="primary"
      (click)="openIntegrationDialog()"
      matTooltip="Adicionar nova integração"
      aria-label="Adicionar nova integração"
      [disabled]="!selectedSistema || integracao"
    >
      Adicionar Integração
    </button>
  </mat-card-actions>
</mat-card>

<mat-card *ngIf="selectedSistema">
  <section *ngIf="integracao; else noIntegracao">
      <mat-card-header>
        <mat-card-title
          >Integração {{ integracao.sistema.dsSistema }}</mat-card-title
        >
      </mat-card-header>
      <mat-card-content>
        <p><strong>Client Id:</strong> {{ integracao.dsClientId }}</p>
        <p>
          <strong>Ultima Atualização:</strong>
          {{ integracao.dataAtualizacao | date : "dd/MM/yyyy hh:mm" }}
        </p>
      </mat-card-content>
      <mat-card-actions>
        <button
          mat-button
          (click)="viewIntegration(integracao.dsClientId)"
          matTooltip="Visualizar integração"
          aria-label="Visualizar integração"
        >
          <img src="../../assets/img/icon_view.png" alt="Visualizar" />
        </button>
        <button
          mat-button
          (click)="editIntegration(integracao.dsClientId)"
          matTooltip="Editar integração"
          aria-label="Editar integração"
        >
          <img src="../../assets/img/icon_edit.png" alt="Editar" />
        </button>
        <button
          mat-button
          color="warn"
          (click)="openDialogExclusao('0ms', '0ms', integracao.id)"
          matTooltip="Excluir integração"
          aria-label="Excluir integração"
        >
          <img src="../../assets/img/icon_remove.png" alt="Deletar" />
        </button>
      </mat-card-actions>
  </section>

  <ng-template #noIntegracao>
    <p>Nenhuma integração selecionada ou disponível para este sistema.</p>
  </ng-template>
</mat-card>
