import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { NotificationService } from './notification.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit, OnDestroy {
  notifications: string[] = [];
  private notificationsSub: Subscription | undefined;

  constructor(private notificationService: NotificationService) { }

  ngOnInit() {
    this.notificationsSub = this.notificationService.notifications$.subscribe(notification => {
      if (notification) { // Check for null which is the initial value
        this.notifications.push(notification);
      }
    });
  }

  deleteNotification(index: number, event: MouseEvent): void {
    event.stopPropagation(); // This stops the event from bubbling up to the menu and closing it
    // Your code to delete the notification goes here
    this.notifications.splice(index, 1);
  }  

  ngOnDestroy() {
    this.notificationsSub?.unsubscribe(); // Prevent memory leaks
  }
}
