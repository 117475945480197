import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import {
  Client,
  IMessage,
  StompConfig,
  StompSubscription,
} from "@stomp/stompjs";
import { environment } from "../../environments/environment";
import { KeycloakService } from "keycloak-angular";

@Injectable({
  providedIn: "root",
})
export class NotificationService implements OnDestroy {
  private serverUrl = environment.websocketUrl; // Confirm this is 'ws://localhost:8082/ws' or appropriate URL
  private stompClient?: Client;
  private notificationSubject = new BehaviorSubject<string | null>(null);
  public notifications$: Observable<string | null> =
    this.notificationSubject.asObservable();

  private notificationSubscription?: StompSubscription;

  constructor(private keycloakService: KeycloakService) {
    this.initializeWebSocketConnection();
  }

  private async initializeWebSocketConnection(): Promise<void> {
    try {
      const token = await this.keycloakService.getToken();
      const username = this.keycloakService.getUsername();
      const wsUrl = this.serverUrl; // Use the server URL from environment variable

      const config: StompConfig = {
        brokerURL: wsUrl,
        reconnectDelay: 20000,
        connectHeaders: {
          token: `${token}`, // Assuming the server expects a header named 'token' for authentication
          username: `${username}`, // Assuming the server expects a header named 'token' for authentication
        },
        onConnect: () => {
          console.log("Connected to WebSocket service");
          this.subscribeToNotifications();
        },
        onStompError: (error) => console.error("STOMP Error:", error),
        onWebSocketError: (error) => console.error("WebSocket Error:", error),
        //debug: (str) => console.log(new Date(), str),
      };

      this.stompClient = new Client(config);
      this.stompClient.activate();
    } catch (error) {
      console.error("Error initializing WebSocket connection:", error);
    }
  }

  private subscribeToNotifications(): void {
    const subscriptionPath = `/user/queue/notifications`; // This path must match the server's configuration
    this.notificationSubscription = this.stompClient?.subscribe(
      subscriptionPath,
      (message: IMessage) => {
        this.handleMessage(message);
      },
      { "durable": "false", "exclusive": "false", "auto-delete": "true" }
    );
  }

  private handleMessage(message: IMessage): void {
    try {
      this.notificationSubject.next(message.body);
    } catch (error) {
      console.error("Error handling message:", error);
    }
  }

  public unsubscribeNotifications(): void {
    if (this.notificationSubscription) {
      this.notificationSubscription.unsubscribe();
      console.log("Unsubscribed from notifications.");
      this.notificationSubscription = undefined; // Clear the reference
    }
  }

  ngOnDestroy(): void {
    console.log("Deactivating WebSocket client...");
    this.unsubscribeNotifications(); // Ensure to unsubscribe first
    this.stompClient?.deactivate();
  }
}
