<app-loading-spinner></app-loading-spinner>
<div class="template-base">
  <nav class="br-skiplink">
    <a class="br-item" href="#main-content" accesskey="1"
      >Ir para o conteúdo (1/4) <span class="br-tag text ml-1">1</span></a
    ><a class="br-item" href="#header-navigation" accesskey="2"
      >Ir para o menu (2/4) <span class="br-tag text ml-1">2</span></a
    ><a class="br-item" href="#main-searchbox" accesskey="3"
      >Ir para a busca (3/4) <span class="br-tag text ml-1">3</span></a
    ><a class="br-item" href="#footer" accesskey="4"
      >Ir para o rodapé (4/4) <span class="br-tag text ml-1">4</span></a
    >
  </nav>
  <!-- header-->
<app-header-not-logged *ngIf="(isLoggedIn$ | async) === false"></app-header-not-logged>
<app-header *ngIf="isLoggedIn$ | async"></app-header>

<main class="d-flex flex-fill mb-5" id="main">
  <div class="container-lg d-flex">
    <div class="col mb-5">
      <!-- Navegacao entre rotas -->
      <div style="width: 100%; height: 17px;">
        <div style="float: left;">
          <app-breadcrumb></app-breadcrumb>
        </div>
      </div>
      <div class="main-content" id="main-content">
        <router-outlet></router-outlet>
        <br />
      </div>
    </div>
  </div>
</main>

<!-- Notificação aparece apenas quando tá logado -->
<div *ngIf="isLoggedIn$ | async" class="notification-container">
  <app-notification></app-notification>
</div>

<app-footer></app-footer>

<div class="br-cookiebar default d-none" tabindex="-1"></div>

</div>
