import { Injectable } from '@angular/core';
import { UsuarioLogado } from '../model/usuariologado.model';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  public setUsuarioLogadoIntoSession(usuarioLogado: UsuarioLogado): void {
    try {
      sessionStorage.setItem('usuarioLogado', JSON.stringify(usuarioLogado));
    } catch (e) {
      console.error('Error saving to sessionStorage', e);
    }
  }

  public getUsuarioLogadoFromSession(): Observable<UsuarioLogado | null> {
    try {
      const data = sessionStorage.getItem('usuarioLogado');
      return of(data ? JSON.parse(data) as UsuarioLogado : null);
    } catch (e) {
      console.error('Error reading from sessionStorage', e);
      return of(null);
    }
  }
}
