import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { PessoaJuridica } from 'src/app/model/pessoajuridica.model';
import { PessoaJuridicaService } from 'src/app/service/pessoajuridica.service';
import { ToastrFunctions } from 'src/app/util/toastr.functions';

@Component({
  selector: 'app-dialog-select-empresa',
  templateUrl: './dialog-select-empresa.component.html'
})
export class DialogSelectEmpresaComponent implements OnInit {

  cnpjs!: string[];
  pessoaJuridicaFormControl = new FormControl('');
  pessoasjuridicas: PessoaJuridica[] = [];
  
  constructor(
    public pessoajuridicaService: PessoaJuridicaService,
    public toastr: ToastrService,
    public dialogRef: MatDialogRef<DialogSelectEmpresaComponent>) {
  }
    
  ngOnInit() {
    this.findPessoaJuridicaByCpf()      
  }

  findPessoaJuridicaByCpf() {
    this.pessoajuridicaService
      .findAllPessoaJuridicaByCpf()
      .pipe()
      .subscribe({
        next: (dados: PessoaJuridica[]) => {
          if (dados) {
            this.pessoasjuridicas = dados;
          }
        },
        error: (e: unknown) => {
          ToastrFunctions.showError(this.toastr, 'Algum erro ocorreu ao pesquisar pessoa jurídica por CPF.');
      },
    });
  }

  savePessoasJuridicas(): void {
    this.dialogRef.close(this.cnpjs);
  }

}
