import { Injectable, OnDestroy } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { KeycloakAuthGuard, KeycloakService } from "keycloak-angular";
import { Functions } from "./functions";
import { UsuarioLogado } from "../model/usuariologado.model";
import { Store } from "@ngrx/store";
import { AppState } from "../interfaces/app-state.interface";
import { Subject } from 'rxjs';

@Injectable({
  providedIn: "root",
})
export class AuthGuard extends KeycloakAuthGuard implements OnDestroy {
  private destroy$ = new Subject<void>();
  private sessionSet = false;

  constructor(
    protected override readonly router: Router,
    protected readonly keycloak: KeycloakService,
    private store: Store<AppState>
  ) {
    super(router, keycloak);
  }

  public async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    if (!this.authenticated) {
      this.router.navigate(["/login"], {
        queryParams: { returnUrl: state.url },
      });
      return false;
    }

    if (!this.sessionSet) {
      await this.setSessionFromAccessToken();
      this.sessionSet = true;
    }

    const requiredRoles = route.data["roles"];
    if (!Array.isArray(requiredRoles) || requiredRoles.length === 0) {
      return true;
    }

    return requiredRoles.every((role) => this.roles.includes(role));
  }

  private async setSessionFromAccessToken() {
    try {
      const token = await this.keycloak.getToken();
      const decodedToken = Functions.decodeToken(token);
      const existingSession = Functions.getUsuarioLogadoFromSession();
  
      // Check if session already exists and should not be overridden
      if (!existingSession || !existingSession.preferredUsername) {
        const newUsuarioLogado = new UsuarioLogado(
          decodedToken["preferred_username"],
          decodedToken["email"],
          decodedToken["given_name"],
          decodedToken["family_name"],
          [], // Set roles here if needed
          token,
          [], // Additional fields if required
          ''  // Additional fields if required,
        );
  
        Functions.setUsuarioLogadoIntoSession(newUsuarioLogado);
      } else {
        console.log("Session already exists, skipping override.");
      }
    } catch (error) {
      console.error("Failed to set session from access token:", error);
      this.router.navigate(["/login"]);
    }
  }
  

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
