<div class="dark-overlay" *ngIf="showSpinner">
</div>
<div class="spinner">
  <mat-spinner *ngIf="showSpinner"></mat-spinner>
</div>
<h1 mat-dialog-title>Adicionar Sistema / Perfil</h1>
<div mat-dialog-content>  
    <div class="main-content pl-sm-3 mt-4" id="main-content">
      <div class="row">
        <form class="example-form">
          <mat-form-field class="example-full-width">
            <mat-label>Sistema</mat-label>
            <input type="text" matInput [formControl]="controlSistema" [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnSistema" (optionSelected)="getSelected($event.option.value)">
              <mat-option *ngFor="let option of filteredOptionsSistema | async" [value]="option">
                {{option.sgSistema}} - {{option.noSistema}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </form>
      </div>
      <div class="row">                
          <form class="example-form">
            <mat-form-field class="example-full-width">
              <mat-label>Perfil</mat-label>
              <mat-select [(ngModel)]="idsPerfis" name="profiles" multiple (selectionChange)="selectedGestorCadastros($event)">
                <mat-option *ngFor="let perfil of perfis" [value]="perfil.id">
                  {{perfil.noPerfil}}
                </mat-option>                      
              </mat-select>
            </mat-form-field>
          </form>                
      </div>      
  </div>
</div>
<div mat-dialog-actions align="center" style="margin-bottom: 15px;">
  <button mat-button mat-dialog-close cdkFocusInitial (click)="insereSistemaPerfisUsuario()">Salvar</button>
  <button mat-button (click)="reset()">Limpar</button>
  <button mat-button mat-dialog-close>Cancelar</button>
</div>



    
 

