<div class="login-page-container" style="margin-top: 20px;">
    <mat-card class="login-card">
      <mat-card-content style="margin-bottom: 20px;">
        <button *ngIf="isPerfilGestorAnvisa || isPerfilGestorAnvisaConsulta || isAdministrador" class="gov-br-button" routerLink="/gestao-perfil-colab-inter">
            Gestão de Perfil para Colaborador Anvisa Interno
        </button>
        <button class="gov-br-button" routerLink="/colab-vinc-cnpj-cnes">
            Gestão de Perfil para Colaborador Externo
        </button>       
      </mat-card-content>
    </mat-card>
  </div>
  