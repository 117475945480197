<h2 mat-dialog-title>Parece que você ainda não possui um Perfil de Acesso ou um CNPJ vinculado.</h2>
<mat-dialog-content>   
    <p>
      Se você possui um Perfil de Acesso e acredita que isso é um erro, verifique se 
      todos os dados foram preenchidos corretamente e tente novamente.
    </p>
    <BR/> 
   <p>
      Se você é um Responsável Legal, não encontramos nenhum CNPJ vinculado ao seu CPF. 
      Para realizar o vínculo de um CPF para um CNPJ é necessário seguir os
      passos descritos em:
    </p>
    <BR/>
    <p>
       <a target="_blank" href="https://www.gov.br/governodigital/pt-br/acessibilidade-e-usuario/atendimento-gov.br/duvidas-na-conta-gov.br/duvidas-na-vinculacao-de-cnpj-no-gov.br/como-vincular-cnpj">Como vincular CNPJ?</a> 
    </p>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-button mat-dialog-close cdkFocusInitial>Ok</button>
</mat-dialog-actions>