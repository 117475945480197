import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { KeycloakClientIntegration } from "src/app/model/keycloakclienterepresentation.model";

@Component({
  selector: "app-view-integration-dialog",
  templateUrl: "./view-integration-dialog.component.html",
  styleUrls: ["./view-integration-dialog.component.scss"],
})
export class ViewIntegrationDialogComponent {
  objectKeys = Object.keys;
  showSecret = false;

  constructor(
    private dialogRef: MatDialogRef<ViewIntegrationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: KeycloakClientIntegration
  ) {}

  toggleSecret() {
    this.showSecret = !this.showSecret;
  }
}
