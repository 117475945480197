import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { KeycloakService } from "keycloak-angular";
import { environment } from "src/environments/environment";
import { Observable, catchError, throwError } from "rxjs";
import { KeycloakClient } from "../model/keycloakclient.model";
import { Integracao } from "../model/integracao.model";
import { KeycloakClientIntegration } from "../model/keycloakclienterepresentation.model";

@Injectable({
  providedIn: "root",
})
export class IntegracaoService {
  private readonly API = `${environment.API}/api/v1/integrador`;

  constructor(
    private httpClient: HttpClient,
    private keycloakService: KeycloakService
  ) {}

  getToken() {
    return this.keycloakService.getToken();
  }

  private createHeaders(): HttpHeaders {
    try {
      const token = this.getToken();
      const headers = new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      });
      return headers;
    } catch (error) {
      throw new Error(`Erro ao obter o token: ${error}`);
    }
  }  

  createIntegration(keycloakClientDTO: KeycloakClient): Observable<string> {
    const headers = this.createHeaders();
    return this.httpClient.post<string>(
      `${this.API}/criar-integracao`,
      keycloakClientDTO,
      { headers, responseType: "text" as "json" }
    ).pipe(
      catchError((error) =>
        throwError(() => new Error(`Erro ao criar integração: ${error}`))
      )
    );
  }
  
  loadIntegration(sistemaId: string): Observable<Integracao> {
    const headers = this.createHeaders();
    return this.httpClient.get<Integracao>(`${this.API}/carregar-integracao/${sistemaId}`, {
      headers,
    }).pipe(
      catchError((error) =>
        throwError(() => error)
      )
    );
  }
  
  loadIntegrationCompleteInformations(
    clientId: string
  ): Observable<KeycloakClientIntegration> {
    const headers = this.createHeaders();
    return this.httpClient.get<KeycloakClientIntegration>(
      `${this.API}/carregar-integracao-informacao-completa/${clientId}`,
      {
        headers,
      }
    ).pipe(
      catchError((error) =>
        throwError(
          () =>
            new Error(`Erro ao carregar informações da integração: ${error}`)
        )
      )
    );
  }
  
  loadIntegrationPartialInformations(
    clientId: string
  ): Observable<KeycloakClient> {
    const headers = this.createHeaders();
    return this.httpClient.get<KeycloakClient>(
      `${this.API}/carregar-integracao-informacao-parcial/${clientId}`,
      {
        headers,
      }
    ).pipe(
      catchError((error) =>
        throwError(
          () =>
            new Error(`Erro ao carregar informações da integração: ${error}`)
        )
      )
    );
  }
  
  updateIntegration(
    integrationId: number,
    updatedClientDTO: KeycloakClient
  ): Observable<string> {
    const headers = this.createHeaders();
    return this.httpClient.put<string>(
      `${this.API}/atualizar-integracao/${integrationId}`,
      updatedClientDTO,
      { headers, responseType: "text" as "json" }
    ).pipe(
      catchError((error) =>
        throwError(() => new Error(`Erro ao atualizar integração: ${error}`))
      )
    );
  }
  
  deleteIntegration(integrationId: number): Observable<string> {
    const headers = this.createHeaders();
    return this.httpClient.delete<string>(
      `${this.API}/deletar-integracao/${integrationId}`,
      { headers, responseType: "text" as "json" }
    ).pipe(
      catchError((error) =>
        throwError(() => new Error(`Erro ao deletar integração: ${error}`))
      )
    );
  }
  
}
