import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import * as PerfisInternoActions from '../actions/perfilinterno.action';
import { PerfilInternoService } from '../service/perfilinterno.service';
import { choosePerfisInterno, loadPerfilInterno } from '../actions/perfilinterno.action';
import { ToastrFunctions } from '../util/toastr.functions';
import { ToastrService } from 'ngx-toastr';
import { PerfisInterno } from '../model/perfisinterno';

@Injectable()
export class PerfilInternoEffects {

  constructor(private actions$: Actions,
              private perfilInternoService: PerfilInternoService,
              private toastr: ToastrService) {}


              choosePerfisInterno$ = createEffect(() =>
                this.actions$.pipe(
                  ofType(choosePerfisInterno),
                  mergeMap((action) =>
                    this.perfilInternoService.findPerfilInternoByFilter(action.nuCpf, action.noPessoaFisica, action.dsEmail, action.page, action.size).pipe(
                      mergeMap((perfisInterno: PerfisInterno) => {
                        if (!perfisInterno.content || perfisInterno.content.length === 0) {
                          const message = 'Colaborador interno não encontrado';
                          ToastrFunctions.showInfo(this.toastr, message);
                          return of(PerfisInternoActions.choosePerfisInternoFailure({ error: message }));
                        }
                        return of(PerfisInternoActions.choosePerfisInternoSuccess({ perfisInterno }));
                      }),
                      catchError((error) => {
                        const errorMessage = 'Ocorreu um erro ao buscar o colaborador interno';
                        ToastrFunctions.showInfo(this.toastr, errorMessage);
                        return of(PerfisInternoActions.choosePerfisInternoFailure({ error }));
                      })
                    )
                  )
                )
              );
              

  loadPerfilInterno$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPerfilInterno),
      mergeMap(() =>
        this.perfilInternoService.findAllPerfilInterno().pipe(
          map((perfilInterno) => PerfisInternoActions.loadPerfilInternoSuccess({ perfilInterno })),
          catchError((error) => of(PerfisInternoActions.loadPerfilInternoFailure({ error })))
        )
      )
    )
  );

  atualizaPerfisUsuarioInterno$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PerfisInternoActions.updatePerfisInternoUsuarioInterno),
      mergeMap(action =>
        this.perfilInternoService.atualizaPerfisUsuarioInterno(action.perfisInternoColaboradorRequest).pipe(
          map((result) => {
            if (result) {
              const message = "Perfil Colaborador Interno atualizado com sucesso.";
              ToastrFunctions.showInfo(this.toastr, message);
              return PerfisInternoActions.updatePerfisInternoUsuarioInternoSuccess({ result: message });
            } else {
              // If result is falsy, return a failure action with an appropriate message
              const errorMessage = "Atualização de Perfil Colaborador Interno falhou.";
              ToastrFunctions.showError(this.toastr, errorMessage);
              return PerfisInternoActions.updatePerfisInternoUsuarioInternoFailure({ error: new Error(errorMessage) });
            }
          }),
          catchError(error => {
            let errorMessage = "Erro ao atualizar Perfil Colaborador Interno.";
            if (error.status === 400) {
              errorMessage += ` Detalhes: ${error.error}`;
            }
            ToastrFunctions.showError(this.toastr, errorMessage);
            return of(PerfisInternoActions.updatePerfisInternoUsuarioInternoFailure({ error }));
          })
        )
      )
    )
  );
  
  
}
