<h1 mat-dialog-title>Adicionar Colaborador</h1>
<div mat-dialog-content>  
    <div class="main-content" id="main-content">
      <div class="row">
        <div>
          <fieldset>            
            <div class="row">
              <div class="col-md-12">
                <div class="br-input">
                  <label for="cpf">CPF</label>
                  <input id="cpf" [(ngModel)]="cpf" type="text" placeholder="Preencha o CPF" mask="000.000.000-00"/>
                </div>
              </div>
              <div class="col-md-12" *ngIf="nome">
                <div class="br-input"><label for="name">Nome</label>
                  <input id="name" [(ngModel)]="nome" [disabled]="true" type="text" />
                </div>
              </div>
              <div class="col-md-12" *ngIf="nome">
                <div class="br-input">
                  <label for="surname">Email</label>
                  <input id="surname" [(ngModel)]="email" [disabled]="true" value="@" type="text"/>
                </div>
              </div>              
            </div>
          </fieldset>
        </div>        
      </div>    
  </div>
</div>
<div mat-dialog-actions align="center" style="margin-bottom: 15px;">
  <button cdkFocusInitial (click)="verificarValidadeCPF()" class="button input-button-custom">Pesquisar</button>
  <button class="button input-button-custom" mat-dialog-close (click)="insereColaborador()" [disabled]="!isSearchSuccessful">Salvar</button>
  <button class="button input-button-white-custom" (click)="reset()">Limpar</button>
  <button class="button input-button-white-custom" (click)="reset()" mat-dialog-close>Cancelar</button>
</div>



    
 

