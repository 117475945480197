import { ToastrService } from "ngx-toastr";

export class ToastrFunctions {
    
    static showInfo(toastr: ToastrService, msg: string) {
        toastr.info(msg, 'Info');
    }
    
    static showWarning(toastr: ToastrService, msg: string) {
        toastr.warning(msg, 'Atenção');
    }

    static showError(toastr: ToastrService, msg: string) {
        toastr.error(msg, 'Erro');
    }
    
}