<h1 mat-dialog-title>Pesquisar Organização com CNPJ</h1>
<div mat-dialog-content>  
    <div class="main-content" id="main-content">
      <div class="row">
        <div>
          <fieldset>            
            <div class="row">
              <div class="col-md-12">
                <div class="br-input">
                  <label for="cnpj">CNPJ</label>
                  <input id="cnpj" [(ngModel)]="cnpj" type="text" placeholder="Preencha o CNPJ" mask="00.000.000/0000-00" [disabled]="true"/>
                </div>
              </div>
              <div class="col-md-12" *ngIf="razaoSocial">
                <div class="br-input"><label for="name">Razão Social</label>
                  <input id="name" [(ngModel)]="razaoSocial" [disabled]="true" type="text" />
                </div>
              </div>                            
            </div>
          </fieldset>
        </div>        
      </div>    
  </div>
</div>
<div mat-dialog-actions align="center" style="margin-bottom: 15px;">  
  <button (click)="selecionaEmpresa()" [disabled]="!isSearchSuccessful" class="button input-button-custom">Selecionar</button>
  <button mat-dialog-close class="button input-button-white-custom">Cancelar</button>
</div>



    
 

