// company.selector.ts
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CompanyState } from "../interfaces/company-state.interface";
import { createIsLoadedSelector } from "./genericselector.interface";

export const selectCompanyFeature = createFeatureSelector<CompanyState>('company');

export const selectCompany = createSelector(
  selectCompanyFeature,
  (state: CompanyState) => state.selectedCompany
);

export const selectAllCompanies = createSelector(
  selectCompanyFeature,
  (state: CompanyState) => state.allCompanies
);

// Selector to get any error that might have occurred during loading
export const selectCompanyLoadError = createSelector(
  selectCompanyFeature,
  (state: CompanyState) => state.error
);

export const selectEmpresas = createSelector(
  selectCompanyFeature,
  (state: CompanyState) => state.empresas
);

export const selectPessoaJuridicaSerpro = createSelector(
  selectCompanyFeature,
  (state: CompanyState) => state.pessoaJuridicaSerpro
);

export const selectCompanyPorte = createSelector(
  selectCompanyFeature,
  (state: CompanyState) => state.selectedCompanyPorte
);

export const selectEmpresasLoaded = createSelector(
  selectCompanyFeature,
  (state: CompanyState) => state.empresasLoaded
);

export const getCompanyIsLoaded =  createIsLoadedSelector(selectCompanyFeature);
