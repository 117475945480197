// src/app/resolvers/perfil.resolver.ts
/* eslint-disable @typescript-eslint/no-unused-vars */
import { inject } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot,
} from "@angular/router";
import { PerfilService } from "../service/perfil.service";
import { catchError, of, tap } from "rxjs";
import { Perfil } from "../model/perfil.model";
import { Store } from "@ngrx/store";
import { loadPerfisFailure, loadPerfisSuccess } from "../actions/auth.actions";

export const perfilResolver: ResolveFn<Perfil[]> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const store = inject(Store);
  return inject(PerfilService)
    .getPerfilAuthorized()
    .pipe(
      catchError((error) => {
        store.dispatch(loadPerfisFailure({ error }));
        return of([]);
      }),
      tap((perfis) => {
        store.dispatch(loadPerfisSuccess({ perfis }));
      })
    );
};
