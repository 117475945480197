import { createAction, props } from "@ngrx/store";
import { Perfil } from "../model/perfil.model";
import { PerfisSistemaCustom } from "../model/perfissistemacustom.model";

export const loadPerfisByUsuarioRepresentante = createAction(
  "[Perfil] Load Perfis by Usuario Representante",
  props<{ idUsuarioRepresentante: string }>()
);
export const loadPerfisByUsuarioRepresentanteSuccess = createAction(
  "[Perfil] Load Perfis by Usuario Representante Success",
  props<{ perfisCustom: PerfisSistemaCustom }>()
);
export const loadPerfisByUsuarioRepresentanteFailure = createAction(
  "[Perfil] Load Perfis by Usuario Representante Failure",
  props<{ error: any }>()
);

export const loadPerfisBySistema = createAction(
  "[Perfil] Load Perfis by Sistema",
  props<{ sistemaId: string }>()
);
export const loadPerfisBySistemaSuccess = createAction(
  "[Perfil] Load Perfis by Sistema Success",
  props<{ perfis: Perfil[] }>()
);
export const loadPerfisBySistemaFailure = createAction(
  "[Perfil] Load Perfis by Sistema Failure",
  props<{ error: any }>()
);

export const loadNodeProfiles = createAction(
  "[Perfil] Load Node Profiles",
  props<{ coSistema: string; idUsuarioRepresentante: string }>()
);

export const loadNodeProfilesSuccess = createAction(
  "[Perfil] Load Node Profiles Success",
  props<{ sistemaId: string; perfis: Perfil[] }>()
);

export const loadNodeProfilesFailure = createAction(
  "[Perfil] Load Node Profiles Failure",
  props<{ error: any }>()
);

export const loadColaboradorProfilesByNode = createAction(
  "[Perfil] Load Colaborador Profiles By Node",
  props<{ coSistema: string; idUsuarioRepresentante: string }>()
);
export const loadColaboradorProfilesByNodeSuccess = createAction(
  "[Perfil] Load Colaborador Profiles By Node Success",
  props<{
    idUsuarioRepresentante: string;
    sistemaId: string;
    perfis: Perfil[];
  }>()
);
export const loadColaboradorProfilesByNodeFailure = createAction(
  "[Perfil] Load Colaborador Profiles By Node Failure",
  props<{ error: any }>()
);

export const loadPerfisMapByUsuarioRepresentante = createAction(
  "[Perfil] Load Perfis Map by Usuario Representante",
  props<{ idUsuarioRepresentante: string }>()
);
export const loadPerfisMapByUsuarioRepresentanteSuccess = createAction(
  "[Perfil] Load Perfis Map by Usuario Representante Success",
  props<{ idUsuarioRepresentante:string, perfisCustom: PerfisSistemaCustom }>()
);
export const loadPerfisMapByUsuarioRepresentanteFailure = createAction(
  "[Perfil] Load Perfis Map by Usuario Representante Failure",
  props<{ error: any }>()
);

export const clearPerfilState = createAction("[Perfil] Clear State");

export const clearNodeProfiles = createAction("[Perfil] Clear Node Profiles");

export const clearColaboradorProfilesNode = createAction(
  "[Perfil] Clear Colaborador Profiles Nodes"
);

export const clearColaboradorProfilesNodeByUsuarioRepresentanteId = createAction(
  "[Perfil] Clear Colaborador Profiles Nodes By Usuario Representante Id",
  props<{ idUsuarioRepresentante: string }>()
);
