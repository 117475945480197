<h1 mat-dialog-title>Atribuição de Perfis em Lote</h1>
<div mat-dialog-content>
  <div class="container">
    <div class="row line-bottom" style="padding-bottom: 10px">
      <div style="width: 28%">
        <div style="font-size: 14px; margin-top: 10px">
          <strong>Ativo</strong>
        </div>
        <div class="column container-vertical-align">
          <div>
            <mat-slide-toggle
              id="activate-user"
              color="primary"
              [(ngModel)]="active"
              (change)="ativaInativaColaboradorLote($event)"
              [disabled]="!isPerfilGestorAnvisa"
            ></mat-slide-toggle>
          </div>
        </div>
      </div>
      <div class="column" style="width: 38%">
        <div style="font-size: 14px"><strong>Data de Inativação</strong></div>
        <div style="position: relative; display: flex; align-items: center">
          <mat-form-field style="flex-grow: 1">
            <mat-label>Selecione uma Data</mat-label>
            <input
              matInput
              [matDatepicker]="picker"
              [(ngModel)]="dtExpiracao"
            />
            <mat-hint *ngIf="!dtExpiracao">DD/MM/YYYY</mat-hint>
            <mat-datepicker-toggle
              matIconSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          <button
            mat-button
            (click)="dtExpiracao = null"
            aria-label="Limpar data"
            *ngIf="dtExpiracao"
            style="margin-left: 8px; margin-bottom: 22px"
          >
            <a href="javascript:void(0);">
              <img src="../../assets/img/icon_trash.png" border="0" />
            </a>
          </button>
        </div>
      </div>
      <div class="column" style="width: 33%">
        <input
          id="update-user-inactivation-date"
          type="button"
          [value]="
            dtExpiracao
              ? 'Atualizar Data de Inativação'
              : 'Limpar Data de Inativação'
          "
          class="input-button-white-custom"
          style="float: left; margin-top: 30px"
          (click)="atualizaDataExpiracaoLote()"
        />
      </div>
    </div>
    <div>
      <div style="font-size: 14px"><strong>Sistemas / Perfis</strong></div>
    </div>
    <div class="row">
      <div class="column" style="width: 33%">
        <select
          [(ngModel)]="selectedSistemaId"
          class="select-custom"
          style="width: 220px; float: left"
          (change)="loadPerfis()"
        >
          <option value="">Selecione um Sistema</option>
          <option *ngFor="let item of sistemas$ | async" [value]="item.id">
            {{ item.noSistema }}
          </option>
        </select>
      </div>
      <div class="column" style="width: 33%">
        <select
          id="select-perfil"
          [(ngModel)]="selectedPerfilId"
          class="select-custom"
          style="width: 220px; float: left"
          (change)="onSelectChange($event)"
          [disabled]="!selectedSistemaId"
        >
          <option value="">Selecione um Perfil</option>
          <option *ngFor="let item of perfis$ | async" [value]="item.id">
            {{ item.noPerfil }}
          </option>
        </select>
      </div>
      <div class="column" style="width: 33%">
        <input
          id="add-perfil-button"
          type="button"
          value="Adicionar Perfil"
          (click)="insereSistemaPerfisUsuarioLote()"
          class="input-button-white-custom"
          style="float: left"
          [disabled]="selectedSistemaId === '' || selectedPerfilId === ''"
        />
      </div>
    </div>
    <div *ngIf="isGestorCadastroSelected">
      <div>
        <div style="font-size: 14px">
          <strong>Pessoas Jurídicas para o Gestor de Cadastro</strong>
        </div>
      </div>
      <div class="row">
        <div class="column" style="width: 66%">
          <mat-form-field class="custom-field" style="min-width: 500px">
            <mat-select [(ngModel)]="cnpjs" multiple>
              <mat-select-trigger aria-label="Selecione as pessoas jurídicas">
                {{
                  cnpjs.length === 0
                    ? "Nenhuma pessoa jurídica selecionada"
                    : cnpjs[0].razaoSocial
                }}
                <span *ngIf="cnpjs.length > 1">
                  (+{{ cnpjs.length - 1 }}
                  {{ cnpjs.length === 2 ? "outro" : "outros" }})
                </span>
              </mat-select-trigger>

              <!-- If profile is Constants.PERFIL_GESTOR_ANVISA -->
              <ng-container
                *ngIf="
                  (profileSelected$ | async) === Constants.PERFIL_GESTOR_ANVISA || (profileSelected$ | async) === Constants.PERFIL_ADMINISTRADOR
                "
              >
                <mat-option
                  *ngIf="selectedCompany$ | async as selectedCompany"
                  [value]="selectedCompany"
                >
                  {{ selectedCompany.razaoSocial }}
                </mat-option>
              </ng-container>

              <!-- Otherwise, show all companies -->
              <ng-container
                *ngIf="
                  (profileSelected$ | async) !== Constants.PERFIL_GESTOR_ANVISA && (profileSelected$ | async) !== Constants.PERFIL_ADMINISTRADOR
                  "
              >
                <mat-option
                  *ngFor="
                    let pj of allCompanies$ | async;
                    trackBy: trackByCompanyFn
                  "
                  [value]="pj"
                >
                  {{ pj.razaoSocial }}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div>
      <div style="font-size: 14px"><strong>Colaboradores</strong></div>
    </div>
    <div class="row">
      <div class="column">
        <div *ngIf="selectEmployees$ | async as dataSource">
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef style="width: 10%"></th>
              <td mat-cell *matCellDef="let element" style="width: 10%">
                <input
                  type="checkbox"
                  [checked]="element.selected"
                  disabled="true"
                />
              </td>
            </ng-container>

            <ng-container matColumnDef="nome">
              <th mat-header-cell *matHeaderCellDef style="width: 25%">Nome</th>
              <td
                mat-cell
                *matCellDef="let element"
                style="width: 25%"
                class="no-hover"
              >
                {{ element.noPessoaFisica }}
              </td>
            </ng-container>

            <ng-container matColumnDef="ativo">
              <th mat-header-cell *matHeaderCellDef style="width: 10%">
                Ativo
              </th>
              <td mat-cell *matCellDef="let element" style="width: 10%">
                <img
                  *ngIf="element.stBloqueado == 'N'"
                  src="../../assets/img/icon_active.png"
                  border="0"
                />
                <img
                  *ngIf="element.stBloqueado == 'S'"
                  src="../../assets/img/icon_inactive.png"
                  border="0"
                />
              </td>
            </ng-container>

            <ng-container matColumnDef="data_expiracao">
              <th mat-header-cell *matHeaderCellDef>Data Inativação</th>
              <td mat-cell *matCellDef="let element" style="width: 15%">
                <div *ngIf="element.dtExpiracao">
                  {{ element.dtExpiracao | date : "dd/MM/yyyy" }}
                </div>
                <div *ngIf="!element.dtExpiracao">NÃO ATRIBUÍDA</div>
              </td>
            </ng-container>

            <ng-container matColumnDef="sistemas_perfis">
              <th mat-header-cell *matHeaderCellDef style="width: 40%">
                Sistemas / Perfis
              </th>
              <td mat-cell *matCellDef="let element" style="width: 40%">
                <ng-container
                  *ngIf="
                    treeDataSources.get(element.idUsuarioRepresentante)
                      | async as treeDataSource;
                    else noData
                  "
                >
                  <mat-tree
                    *ngIf="treeDataSource.data.length > 0; else noData"
                    [dataSource]="treeDataSource"
                    [treeControl]="treeControls[element.idUsuarioRepresentante]"
                    class="example-tree"
                    style="background: transparent"
                  >
                    <!-- Tree node template for leaf nodes -->
                    <mat-tree-node
                      *matTreeNodeDef="let node; when: !hasChild"
                      matTreeNodePadding
                    >
                      <button mat-icon-button disabled></button>
                      <span>
                        <img
                          src="../../assets/img/icon_circle.png"
                          style="margin-right: 5px"
                        />
                        {{ node.item }}
                      </span>
                      <button
                        mat-button
                        (click)="
                          openDialogExclusao(
                            element.idUsuarioRepresentante,
                            node
                          )
                        "
                        aria-label="Delete {{ node.item }}"
                      >
                        <img src="../../assets/img/icon_trash.png" border="0" />
                      </button>
                    </mat-tree-node>

                    <!-- Tree node template for expandable nodes -->
                    <mat-tree-node
                      *matTreeNodeDef="let node; when: hasChild"
                      matTreeNodePadding
                    >
                      <button
                        mat-icon-button
                        matTreeNodeToggle
                        [attr.aria-label]="'Toggle ' + node.item"
                      >
                        <mat-icon>
                          {{
                            treeControls[
                              element.idUsuarioRepresentante
                            ].isExpanded(node)
                              ? "expand_more"
                              : "chevron_right"
                          }}
                        </mat-icon>
                      </button>
                      <span>{{ node.item }}</span>
                      <button
                        mat-button
                        (click)="
                          openDialogExclusao(
                            element.idUsuarioRepresentante,
                            node
                          )
                        "
                        aria-label="Delete {{ node.item }}"
                      >
                        <img src="../../assets/img/icon_trash.png" border="0" />
                      </button>
                    </mat-tree-node>
                  </mat-tree>
                </ng-container>
                <ng-template #noData>
                  <div style="margin-left: 20px">NÃO ATRIBUÍDOS</div>
                </ng-template>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="container-buttons">
    <button mat-dialog-close class="button input-button-white-custom">
      Fechar
    </button>
  </div>
</div>
